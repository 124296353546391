import styled from 'styled-components'

const buttonBackgroundColorHandler = (type) => {
  switch (type) {
    case 'primary':
      return 'var(--color-button-primary)'
    case 'ghost':
      return 'transparent'
  }
}

const buttonColorHandler = (type) => {
  switch (type) {
    case 'primary':
      return 'var(--color-white)'
    case 'ghost':
      return '#228CB8'
  }
}

export const ExportConversationsContainer = styled.div`

`

export const ExportConversationsControls = styled.div`
  display: flex;
`

export const TimeFrameSelector = styled.button`
  border: 1px solid var(--color-mischka-grey);
  border-right: none;
  background-color: var(--color-white);
  padding: 14px 14px 14px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 159px;
  height: 45px;
  border-radius: 10px 0 0 10px;
  
  & svg {
    min-width: 11px;
  }
`

export const SelectorTitle = styled.div`
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--color-text-secondary);
  word-break: break-word;
`

export const ExportButton = styled.button.attrs((props) => ({
  disabled: props.isDisabled
}))`
  border: none;
  padding: 12px;
  border-radius: 0 10px 10px 0;
  height: 45px;
  background-color: var(--color-button-primary);
  
  &:disabled {
    cursor: auto;
    background-color: #ACB0C0;
  }
  
  & svg {
    display: block;
  }
`

export const PopoverHeader = styled.h3`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--color-text-secondary);
  padding: 16px 16px 0;
  margin-bottom: 16px;
`

export const PopoverMain = styled.div`

`

export const PopoverFooter = styled.div`
  box-shadow: 0 -1px 0 0 rgba(53, 64, 82, 0.10);
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

export const PopoverButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: ${props => buttonBackgroundColorHandler(props.type)};
  color: ${props => buttonColorHandler(props.type)};
`
