import React from 'react'
import { NoChartDataIcon } from '../icons/Icons'

import classes from './styles.module.scss'
import classNames from 'classnames'

interface Props {
  text: string
  isUserData?: boolean
}

const NoChartData: React.FC<Props> = ({ text, isUserData = false }) => (
  <div className={classNames(classes.container, [
    isUserData && classes.noUsersDataContainer
  ])}>
    <NoChartDataIcon />
    <p className={classes.text}>{text}</p>
  </div>
)

export default NoChartData
