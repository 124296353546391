import styled from 'styled-components'
import { Title as TitleBase } from '../DeleteServiceUserModal/DeleteServiceUserModal.styles'

export const Title = styled(TitleBase)`

`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  
  & div > button {
    color: #228CB8;
  }
`
