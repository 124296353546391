import {
  UPDATE_SEARCH_QUERY,
} from '../actions/chat'

const initialState = {
  searchQuery: '',
}

const searchQuery = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      }
    default:
      return state
  }
}

export default searchQuery
