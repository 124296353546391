import styled from 'styled-components'
import * as S from '../../../nlp/components/knowledgeBase/UploadForm/UploadForm.style'
import { UploadingStatuses } from '../../helpers/constants'

const colorSetter = (uploadingStatus) => {
  switch (uploadingStatus) {
    case UploadingStatuses.ERROR:
      return 'var(--color-text-error)'
    case UploadingStatuses.SUCCESS:
      return 'var(--color-text-success)'
  }
}

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const SelectContainer = styled.div`
  margin-top: 24px;
`

export const SelectLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-primary);
  margin-bottom: 8px;
`

export const UploadContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ButtonContainer = styled.div`
  margin-bottom: 24px;
`

export const Button = styled(S.Button)`
  width: 205px;
  padding: 0 16px;
  background: ${props => (props.isUploading ?
    'var(--color-input-stroke)' : props.hasSuccessStatus ?
      'var(--color-table-row-primary)' : 'var(--color-button-primary)')};
  color: ${props => (props.isUploading ?
    'var(--color-text-placeholder)' : props.hasSuccessStatus ?
      'rgb(var(--color-primary))' : 'var(--color-white)'
  )};
  
  pointer-events: ${props => (props.isUploading || props.hasSuccessStatus) && 'none'};
`

export const ButtonTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

export const LoaderContainer = styled.div`
  margin-right: 16px;
`

export const IconContainer = styled(LoaderContainer)`

`

export const UploadTitle = styled(S.Title)`
  text-align: center;
  width: 300px;
  color: ${props => colorSetter(props.uploadingStatus)};
`

export const DownloadButton = styled.button`
  display: block;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  color: rgb(var(--color-primary));
  text-decoration: underline;
  padding: 0;
`
