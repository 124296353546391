import styled from 'styled-components'

export const InputEditorContainer = styled.div`
  max-height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InputEditorHolder = styled.div`
  border: 1px solid var(--color-ghost-grey);
  width: 100%;
  border-radius: 10px;
  display: flex;
  transition: 1s all ease;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: 10px;
  max-height: 40px;
`

export const InputEditorInput = styled.input`
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  background-color: var(--color-white);
  width: 100%;
  padding: 0;
`

export const InputEditorButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #616581;
  background-color: transparent;
  border: none;
  padding: 0;
`
