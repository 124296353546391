import { BACKEND_URL } from '../../../configs'
import { alertError, createJsonHeaders, alertSuccess, logoutOnRequestOrResponseJson } from '../../../api'
import { saveTotalElements, saveTotalPages, saveUsers, setIsLoadingUsers } from '../actions/usersAction'
import { DatePeriodType, UsersManagementType, UserType } from '../model/usersModel'
import { FormType } from '../components/InviteUserModal/InviteUserModal'

export const getUsers = (botId: string, page: number, search = '') => {
  const encodedQuery = encodeURIComponent(search)
  fetch(`${BACKEND_URL}/users/management/bot/${botId}?page=${page}&search=${encodedQuery}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: UsersManagementType) => {
        setIsLoadingUsers(false)
        saveUsers(json.user_list)
        saveTotalPages(json.total_pages)
        saveTotalElements(json.total_elements)
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const updateUser = async (updatedUser: UserType): Promise<Response> => {
  return fetch(`${BACKEND_URL}/users/management`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(updatedUser)
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(json => {
        alertSuccess('User updated', 2000)
        return json
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const deleteUser = async (userId: number) => {
  return fetch(`${BACKEND_URL}/users/management/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('You successfully removed user from the Chatbot.', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const resetUserPassword = (userId: number) => {
  fetch(`${BACKEND_URL}/users/management/reset/password/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        // eslint-disable-next-line max-len
        alertSuccess('You successfully initiated password reset. The user will change their password on the next login.', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const resendUserInvitation = async (userId: number) => {
  return fetch(`${BACKEND_URL}/users/management/resend/invite/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('Invitation successfully resended', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const uploadFile = async (botId: string, file: FormData) => {
  return fetch(`${BACKEND_URL}/users/invite/bulk?botId=${botId}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  }).then(response => {
    return response
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const inviteNewUser = async (form: FormType & { botId: string }) => {
  return fetch(`${BACKEND_URL}/users/invite`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(form)
  }).then(response => {
    return logoutOnRequestOrResponseJson(response).then(() => {
      // eslint-disable-next-line max-len
      alertSuccess('You successfully invited user(s) to the Chatbot. Instructions have been sent to the user’s email.', 2000)
      return response
    })
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const exportConversationLogs = async (botId: string, userId: number, datePeriod: DatePeriodType) => {
  return fetch(`${BACKEND_URL}/ai-chat/${botId}/user/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(datePeriod)
  }).then(response => {
    if (!response.ok) {
      alertError('Error exporting conversation logs')
    }
    return response.blob()
  }).then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'chat_history.csv'
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }).catch(() => {
    alertError('Error exporting conversation logs')
  })
}
