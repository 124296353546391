import styled from 'styled-components'
import Select from 'react-select'

const ExternalLinkWrap = styled.a`
  cursor: pointer;
  margin-left: 8px;
`

const EditIconWrap = styled.button`
  all: unset;
  position: relative;

  & svg path {
    fill: var(--color-brandeis-blue);
  }
`

const StyledSelect = styled(Select)`
  background-color: ${props => props.backgroundColor || 'var(--color-light-peach)'};
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const SourceWrap = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
`

const LinkIconWrap = styled.button`
  all: unset;
`

const MessageWrap = styled.span`
  word-break: break-word;
  white-space: pre-wrap;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
`

export { ExternalLinkWrap, SourceWrap, LinkIconWrap, MessageWrap, EditIconWrap, ButtonContainer }
