import styled from 'styled-components'

export const CheckboxInput = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: var(--color-white);
  border: 1px solid #d7d8df;
  border-radius: 2px;
  
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`

export const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  color: var(--color-text-secondary);
  
  & ${CheckboxInput}:checked ~ ${Checkmark} {
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
  }
  
  & ${CheckboxInput}:checked ~ ${Checkmark}:after {
    display: block;
  }
  
  & ${Checkmark}:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 14px;
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`
