import { BASE_URL, BACKEND_URL } from 'configs'
import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson, customResponseParser } from 'api'
import { AGENT_JOINED, AGENT_LEFT } from 'constants/widgetActionsType'

export function getMessages(chatId, page, botId) {
  const params = new URLSearchParams({
    page,
    size: '20',
    sort: 'id,desc',
  })

  return fetch(`${BASE_URL}/bot/${botId}/support/user/${chatId}/chat?${params}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function getAllMessages(chatId, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/user/${chatId}/chat/all`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function closeUserRequest(json, botId, chatId, publicBotId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/backUserToBot`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).then(() => sendAction(AGENT_LEFT, publicBotId, chatId))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function assignSupportRequest(botId, json, chatId, publicBotId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/assign`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  })
    .then(() => sendAction(AGENT_JOINED, publicBotId, chatId))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function getAdminsList(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/admins`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function setMessageRead(chatId, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/user/${chatId}/read`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
  }).catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function sendAction(actionType, botId, chatId) {
  const event = {
    action: {
      type: actionType,
    },
    chatId: chatId?.toString()
  }
  if (chatId) {
    return fetch(`${BACKEND_URL}/widget/${botId}/chat/${chatId}/action`, {
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(event),
    })
  }
}
