import React, { FC } from 'react'
import Modal from '@material-ui/core/Modal'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import classes from '../../../nlp/components/knowledgeBase/SourcesTable/styles.module.scss'
import { Container, Subtitle, Title } from './DuplicateAttentionModal.styles'

interface Props {
  open: boolean
  onClose: () => void
  userNames: string
}

const DuplicateAttentionModal: FC<Props> = ({
  open,
  onClose,
  userNames
}) => {

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Title>
          Same name combination for {userNames} already exists.
        </Title>
        <Subtitle>
          Delete duplicate or add indicator (e.g. middle name) to differentiate service user.
        </Subtitle>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
      </Container>
    </Modal>
  )
}

export default DuplicateAttentionModal
