import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//import CloseRequestButton from '../CloseRequestButton/CloseRequestButton'
//import AssignToMeButton from '../AssignToMeButton/AssignToMeButton'
//import AssignToPopover from '../AssignToPopover/AssignToPopover'
import UserName from '../UserName/UserName'

//import { CHAT_PLATFORM } from '../../../../constants/chatPlatform'
//import { SUPPORT_STATUSES } from '../../../../constants/suportStatuses'
//import { CHAT_STATUS } from '../../../../constants/chatStatus'
import { styles } from './styles'
//import { getMinutesLeft } from '../../../../helpers/getMinutesLeft'
//import { ROLE } from '../../../../security'

const ChatHeader = props => {
  const { classes } = props

//  const isAdmin =
//    adminUser.botAccesses.filter(
//      ba => ba.botId === Number(match.params.botId),
//    )[0].role === ROLE.ADMIN

//  const isAssignedToMe =
//    user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
//    user.supportRequest?.adminId === adminUser.id

//  const isAssigned = user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

//  const isExpired = user.supportRequest?.status === SUPPORT_STATUSES.EXPIRED

//  const isOpen = user.supportRequest?.status === SUPPORT_STATUSES.OPEN

//  const isPending = user.supportRequest?.status === SUPPORT_STATUSES.PENDING

//  const minutesLeft = user && getMinutesLeft(user.lastUserActionAt) < 0

//  const isDisconnectedUser =
//    (user?.platform === CHAT_PLATFORM.WHATSAPP && minutesLeft) ||
//    user.chatStatus === CHAT_STATUS.DISCONNECTED

//  const statusNotification =
//    user?.platform === CHAT_PLATFORM.WHATSAPP
//      ? "User didn't interact with the chatbot for 24h. You can’t send a message anymore"
//      : 'User closed the chat and you can’t send a message anymore'

  return (
    <div className={classes.container}>
      <UserName />
    </div>
  )
}

ChatHeader.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  adminUser: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.activeUser,
  adminUser: state.adminUser,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ChatHeader)),
)
