import React, { FC, useMemo } from 'react'
import Modal from '@material-ui/core/Modal'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import classes from '../../../nlp/components/knowledgeBase/SourcesTable/styles.module.scss'
import { Subtitle, Title, UserName } from './DeleteServiceUserModal.styles'

interface Props {
  open: boolean
  selectedUser: any
  onClose: () => void
  onDelete: (source: any) => void
}

export const DeleteServiceUserModal: FC<Props> = ({
  open,
  selectedUser,
  onClose,
  onDelete
}) => {
  const userName = useMemo(() => {
    return selectedUser ? selectedUser.firstName : 'selected service users'
  }, [selectedUser])

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <Title>
          Are you sure you want to delete <UserName>{userName}</UserName> from CareBrain?
        </Title>
        <Subtitle>
          Make sure you previously exported all the conversations logs.
        </Subtitle>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
        <div className={classes.buttonsWrap}>
          <div className={classes.deleteButton} onClick={onDelete}>
            Delete
          </div>
          <SubmitButton onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}
