import styled from 'styled-components'
import {
  ButtonTitle as UserButtonTitle,
  UsersButtonWrap,
  UsersContainer,
  UsersControlLine,
  UserSearchWrap,
  UsersHeadlineWrap,
} from '../users/Users.styles'

export const ServiceUsersContainer = styled(UsersContainer)``

export const ServiceUsersHeadlineWrap = styled(UsersHeadlineWrap)``

export const ServiceUsersControlLine = styled(UsersControlLine)``

export const ServiceUsersSearchWrap = styled(UserSearchWrap)``

export const ServiceUsersButtonWrap = styled(UsersButtonWrap)``

export const ButtonTitle = styled(UserButtonTitle)``
