import styled from 'styled-components'
import { HeadlineWrap } from '../nlp/NlpContainer.style'

export const UsersContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 40px;
`

export const UsersHeadlineWrap = styled.div(HeadlineWrap)

export const UserSearchWrap = styled.div`
  width: 400px;
`

export const UsersControlLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
`

export const UsersButtonWrap = styled.div`
  & button {
    text-transform: unset;
  }

  & button:first-child {
    margin-right: 10px;
  }

  & button svg path {
    stroke: var(--color-text-on-primary);
  }
`

export const ButtonTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: var(--color-text-on-primary);
`
