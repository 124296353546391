export const styles = () => ({
  email: {
    color: '#ACB0C0',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  emailWrap: {
    display: 'flex',
  },
})
