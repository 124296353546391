import React, { useEffect, useRef } from 'react'
import Buttons from '../Buttons/Buttons'
import { TextMessageType } from 'models/ChatMessagesTypes'
import linkEditorListener from '../../helpers/linkEditorListener'
import convertBoldText from 'helpers/convertBoldText'

import * as S from './TextMessageButton.style'
import xss from 'xss'
import { xssOptions } from 'constants/xssOptions'

interface Props {
  message: TextMessageType
  isRtl: boolean
  highlight: boolean
  postbackIds: string[]
  searchQuery: string
}

export const TextMessageButton: React.FC<Props> = props => {
  const { message, isRtl, highlight, postbackIds, searchQuery } = props

  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  const highlightText = (text, query) => {
    if (!query || !text) return text

    const parts = text.split(new RegExp(`(${query})`, 'gi'))
    return parts
      .map(part => {
        return part.toLowerCase() === query.toLowerCase()
          ? `<span style="background-color: #ed5e79;text-decoration:none;">${part}</span>`
          : part
      })
      .join('')
  }

  const messageContent = message.text || message.postback
  const highlightedContent = highlightText(messageContent, searchQuery)
  const sanitizedContent = xss(convertBoldText(highlightedContent), xssOptions)

  return (
    <S.TextMessageButton highlight={highlight}>
      <S.ChatBubble
        isRtl={isRtl}
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        ref={output}
      />
      {!!message?.buttons?.length && <Buttons buttons={message?.buttons} postbackIds={postbackIds}/>}
    </S.TextMessageButton>
  )
}
