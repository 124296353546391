export const styles = () => ({
  userName: {
    color: 'var(--color-oxford-blue)',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  userNameWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
})
