import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'

const UserEmail = props => {
  const { classes, email } = props

  return (
    <div className={classes.emailWrap}>
      <p className={classes.email}>{email}</p>
    </div>
  )
}
UserEmail.propTypes = {
  classes: PropTypes.object,
  url: PropTypes.string,
}

export default withStyles(styles)(UserEmail)
