import React, { Dispatch, FC, SetStateAction } from 'react'
import Input from 'uiKit/inputs/Input'
import { ErrorsType, FormFields, FormType } from 'models/ServiceUsersTypes.d'
import { DropdownContainer, DropdownLabel, InputsContainer } from './AddNewServiceUserModal.styles'
import CarersSelect from '../CarersSelect/CarersSelect'

interface Props {
  botId: string
  form: FormType
  errors: ErrorsType
  setForm: Dispatch<SetStateAction<FormType>>
  setErrors: Dispatch<SetStateAction<ErrorsType>>
  setIsPrimaryButtonDisabled: Dispatch<SetStateAction<boolean>>
}

export const OneUserTab: FC<Props> = ({
  botId,
  form,
  errors,
  setForm,
  setErrors,
  setIsPrimaryButtonDisabled
}) => {
  const onChangeHandler = (type, value) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value

    const preparedValue =
      type === FormFields.CARE_PROFESSIONALS
        ? value.map(professional => professional.value)
        : value

    setForm(prevState => ({
      ...prevState,
      [type]: preparedValue
    }))

    if (type !== FormFields.CARE_PROFESSIONALS) {
      if (!trimmedValue) {
        setErrors(prevState => ({
          ...prevState,
          [type]: 'This field cannot be empty.'
        }))
      } else {
        setErrors(prevState => ({
          ...prevState,
          [type]: ''
        }))
      }
    }

    const isFormValid = !!form.first_name.trim() && !!form.last_name.trim()
    setIsPrimaryButtonDisabled(!isFormValid)
  }

  return (
    <>
      <InputsContainer>
        <Input
          title='First Name'
          placeholder='Enter user’s first name'
          value={form.first_name}
          onChange={(e) => onChangeHandler(FormFields.FIRST_NAME, e.target.value)}
          error={!!errors.first_name}
          errorMessage={errors.first_name}
          maxLength={30}
        />
        <Input
          title='Last Name'
          placeholder='Enter user’s last name'
          value={form.last_name}
          onChange={(e) => onChangeHandler(FormFields.LAST_NAME, e.target.value)}
          error={!!errors.last_name}
          errorMessage={errors.last_name}
          maxLength={30}
        />
      </InputsContainer>
      <DropdownContainer>
        <DropdownLabel>
          Care Professionals
        </DropdownLabel>
        <CarersSelect
          botId={botId}
          assignedProfessionals={form.care_professionals}
          onChangeHandler={(value) => onChangeHandler(FormFields.CARE_PROFESSIONALS, value)}
          placeholder='Assign care professionals'
        />
      </DropdownContainer>
    </>
  )
}
