import styled from 'styled-components'

export const SourceContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 214px;
    margin-right: 24px;
    
`

export const SourceList = styled.ul`
    border-radius: 8px;
    border: 1px solid var(--color-mischka-grey);
    background-color: rgb(var(--color-light));
    padding: 9px 8px 9px 16px;
    margin: 0 8px 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 160px;
    overflow-y: auto;
    max-width: 214px;
    min-width: 214px;
`

export const SourceItem = styled.li<{ sourceType: string }>`
    list-style-type: none;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const ItemName = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 10px;
    color: var(--color-text-secondary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 214px;
    cursor: pointer;
`

export const OpenInNewWindowAction = styled.button`
    border: none;
    background: transparent;
    & svg {
        width: 12px;
        height: 12px;

        & path {
            stroke: var(--color-pale-sky);
        }
    }
    height: fit-content;
    margin: 0;
`

export const NoItems = styled.li`
    list-style-type: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: var(--color-text-placeholder);
`

export const Divider = styled.hr`
    border-top: 1px solid #202124;
    border-radius: 1px;
    height: 1px;
    margin: 7px 0 7px 0;
    max-height: 1px;
`

export const ExternalLinkWrap = styled.div`
    display: flex;                
    justify-content: flex-end;     
    align-items: center;          
    flex-grow: 1;                
    margin-left: auto;             
    cursor: pointer;
    border: none;
    background: transparent;
    & svg {
        width: 12px;
        height: 12px;

        & path {
            stroke: var(--color-pale-sky);
        }
    }
`
