import React, { FC, useMemo, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { FormControlLabel } from '@material-ui/core'
import { DownloadIcon } from 'uiKit/icons/DownloadIcon'
import { LanguageArrowDown } from 'uiKit/icons/LanguageArrowDown'
import {
  ExportButton,
  ExportConversationsContainer,
  ExportConversationsControls,
  PopoverButton,
  PopoverFooter,
  PopoverHeader,
  PopoverMain,
  SelectorTitle,
  TimeFrameSelector,
} from './ExportConversations.styles'
import classes from './styles.module.scss'
import { TimeframeFormValues } from '../../../users/helpers/constants'
import moment from 'moment'
import DateRange from '../../../../uiKit/DateRange'
import { exportConversations } from '../../api/serviceUsersApi'

const timeData = {
  [TimeframeFormValues.TODAY]: {
    startDate: moment().subtract(1, 'week').add(1, 'day'),
    endDate: moment(),
    label: 'Today'
  },
  [TimeframeFormValues.YESTERDAY]: {
    startDate: moment().subtract(1, 'day'),
    endDate: moment(),
    label: 'Yesterday'
  },
  [TimeframeFormValues.LAST_7_DAYS]: {
    startDate: moment().subtract(1, 'week').add(1, 'day'),
    endDate: moment(),
    label: 'Last 7 days'
  },
  [TimeframeFormValues.LAST_30_DAYS]: {
    startDate: moment().subtract(1, 'month').add(1, 'day'),
    endDate: moment(),
    label: 'Last 30 days'
  },
  [TimeframeFormValues.ALL_TIME]: {
    startDate: moment('2024-01-01'),
    endDate: moment(),
    label: 'All time'
  }
}

interface Props {
  botId: string
  userId: number
}

export const ExportConversations: FC<Props> = ({ botId, userId }) => {
  const [isOpenExportConversations, setOpenExportConversations] = useState(false)
  const [target, setTarget] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [timeFrameValue, setTimeFrameValue] = useState(null)
  const [customRangeValue, setCustomRangeValue] = useState({
    startDate: moment().subtract(1, 'week').add(1, 'day'),
    endDate: moment()
  })
  const isExportButtonDisabled = useMemo(() => !timeFrameValue, [timeFrameValue])

  const RadioButton = (
    <Radio style ={{
      color: 'var(--color-button-primary)'
    }}/>
  )

  const onChangeHandler = (e) => {
    const value = e.target.value
    setSelectedValue(value)
  }

  const setCustomDate = (startDate, endDate) => {
    setCustomRangeValue({
      startDate,
      endDate
    })
  }

  const resetHandler = () => {
    setSelectedValue(null)
  }

  const applyHandler = () => {
    if (selectedValue === TimeframeFormValues.CUSTOM) {
      setTimeFrameValue({
        ...customRangeValue,
        label: 'Custom'
      })
    } else {
      setTimeFrameValue(timeData[selectedValue])
    }

    setOpenExportConversations(false)
  }

  const exportConversationsHandler = () => {
    const dateObject = {
      from: moment(timeFrameValue.startDate).format('YYYY-MM-DD'),
      to: moment(timeFrameValue.endDate).format('YYYY-MM-DD'),
    }

    exportConversations(botId, userId, dateObject)
  }

  const timeFrameTitle = useMemo(() => {
    return timeFrameValue ?
      selectedValue === 'custom' ?
        `${moment(timeFrameValue.startDate).format('DD, MMM YYYY')} - ${moment(timeFrameValue.endDate).format('DD, MMM YYYY')}`
        : timeFrameValue.label
      : 'Select timeframe'
  }, [timeFrameValue])

  return (
    <ExportConversationsContainer>
      <ExportConversationsControls ref={node => setTarget(node)}>
        <TimeFrameSelector onClick={() => setOpenExportConversations(true)}>
          <SelectorTitle>
            {timeFrameTitle}
          </SelectorTitle>
          <LanguageArrowDown color='var(--color-spun-pearl-grey)' />
        </TimeFrameSelector>
        <ExportButton
          onClick={exportConversationsHandler}
          isDisabled={isExportButtonDisabled}
        >
          <DownloadIcon color='white' />
        </ExportButton>
      </ExportConversationsControls>
      <Popover
        open={isOpenExportConversations}
        anchorEl={target}
        classes={{ paper: classes.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setOpenExportConversations(prevState => !prevState)}
      >
        <PopoverHeader>
          Select timeframe
        </PopoverHeader>
        <PopoverMain>
          <RadioGroup
            className={classes.radioGroup}
            value={selectedValue}
            onChange={onChangeHandler}
          >
            <FormControlLabel
              control={RadioButton}
              label='Today'
              value={TimeframeFormValues.TODAY}
            />
            <FormControlLabel
              control={RadioButton}
              label='Yesterday'
              value={TimeframeFormValues.YESTERDAY}
            />
            <FormControlLabel
              control={RadioButton}
              label='Last 7 days'
              value={TimeframeFormValues.LAST_7_DAYS}
            />
            <FormControlLabel
              control={RadioButton}
              label='Last 30 days'
              value={TimeframeFormValues.LAST_30_DAYS}
            />
            <FormControlLabel
              control={RadioButton}
              label='All time'
              value={TimeframeFormValues.ALL_TIME}
            />
            <FormControlLabel
              control={RadioButton}
              label='Custom'
              value={TimeframeFormValues.CUSTOM}
            />
          </RadioGroup>
          <DateRange
            startDate={customRangeValue.startDate}
            endDate={customRangeValue.endDate}
            setDate={setCustomDate}
            customClasses={classes.exportConversationRange}
          />
        </PopoverMain>
        <PopoverFooter>
          <PopoverButton
            type='ghost'
            onClick={resetHandler}
          >
            Reset
          </PopoverButton>
          <PopoverButton
            type='primary'
            onClick={applyHandler}
          >
            Apply
          </PopoverButton>
        </PopoverFooter>
      </Popover>
    </ExportConversationsContainer>
  )
}
