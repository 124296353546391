import styled from 'styled-components'

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: var(--color-oxford-blue);
  text-align: center;
  margin-bottom: 16px;
`

export const Subtitle = styled(Title)`
  margin-bottom: 60px;
`

export const UserName = styled.span`
  font-weight: 700;
`
