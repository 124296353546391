import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Flag from 'react-world-flags'

import { styles } from './styles'
import InteractionTimer from '../InteractionTimer/InteractionTimer'
import { MY_SUPPORT_ICON, SUPPORT_ICON } from '../UserDialogPreview/config'
import moment from 'moment'
import { CHAT_PLATFORM } from '../../../../constants/chatPlatform'
import Tooltip from 'uiKit/StyledTooltip/StyledTooltip'

const UserNameBlock = props => {
  const { classes, user, isAssigned, isAssignedToCurrentAdmin } = props
  const supportIcon = (isAssignedToCurrentAdmin && MY_SUPPORT_ICON) || (isAssigned && SUPPORT_ICON)

  const maxEmailLength = 39

  const lastMessageDate = moment(user?.lastEventAt).isSame(new Date(), 'day')
    ? 'Today'
    : moment(user?.lastEventAt).format('MMM DD')

  const isFromWhatsApp = user?.platform === CHAT_PLATFORM.WHATSAPP


  const truncateEmail = (email, maxLength) => {
    if (email.length > maxLength) {
      return `${email.slice(0, maxLength)}...`
    }
    return email
  }

  return (
    <div className={classes.userMainInfo}>
      <div className={classes.userNameWrapper}>
        {user?.email ? (
          <Tooltip classes={{ tooltip: classes.tooltip }}
            title={truncateEmail(user?.email, maxEmailLength)} placement="top">
            <p className={classes.name}>
              {user?.firstName} {user?.lastName} | {user?.patient}
            </p>
          </Tooltip>
        ) : (
          <p className={classes.name}>
            {user?.firstName} {user?.lastName} | {user?.patient}
          </p>
        )}

        {supportIcon && <img src={supportIcon} className={classes.supportIcon} alt={'Support Icon'} />}

        {isFromWhatsApp && user.lastUserActionAt && (
          <InteractionTimer lastInteractionDate={user?.lastUserActionAt} />
        )}
        {user?.countryCode && user.countryCode.toLowerCase() !== 'ru' && (
          <Flag className={classes.flag} code={user.countryCode} height="10" title={user.countryCode}/>
        )}
      </div>

      <div className={classes.lastMsgTime}>{lastMessageDate}</div>
    </div>
  )
}
UserNameBlock.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(UserNameBlock)
