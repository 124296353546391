/* eslint-disable */
import React from 'react'

export const FileIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M17.4931 4.15109L13.7266 0.587734C13.3259 0.208711 12.8016 0 12.2501 0H5.0791C3.89445 0 2.93066 0.963789 2.93066 2.14844V17.8516C2.93066 19.0362 3.89445 20 5.0791 20H16.0166C17.2013 20 18.165 19.0362 18.165 17.8516V5.7118C18.165 5.12395 17.9201 4.55508 17.4931 4.15109ZM16.3549 4.6875H13.4385C13.3308 4.6875 13.2432 4.59988 13.2432 4.49219V1.74359L16.3549 4.6875ZM16.0166 18.8281H5.0791C4.54062 18.8281 4.10254 18.39 4.10254 17.8516V2.14844C4.10254 1.60996 4.54062 1.17188 5.0791 1.17188H12.0713V4.49219C12.0713 5.24605 12.6846 5.85938 13.4385 5.85938H16.9932V17.8516C16.9932 18.39 16.5551 18.8281 16.0166 18.8281Z"
        fill="#00A0E3" />
      <path
        d="M14.7275 7.8125H6.13379C5.8102 7.8125 5.54785 8.07484 5.54785 8.39844C5.54785 8.72203 5.8102 8.98438 6.13379 8.98438H14.7275C15.0511 8.98438 15.3135 8.72203 15.3135 8.39844C15.3135 8.07484 15.0511 7.8125 14.7275 7.8125Z"
        fill="#00A0E3" />
      <path
        d="M14.7275 10.9375H6.13379C5.8102 10.9375 5.54785 11.1998 5.54785 11.5234C5.54785 11.847 5.8102 12.1094 6.13379 12.1094H14.7275C15.0511 12.1094 15.3135 11.847 15.3135 11.5234C15.3135 11.1998 15.0511 10.9375 14.7275 10.9375Z"
        fill="#00A0E3" />
      <path
        d="M8.97441 14.0625H6.13379C5.8102 14.0625 5.54785 14.3248 5.54785 14.6484C5.54785 14.972 5.8102 15.2344 6.13379 15.2344H8.97441C9.29801 15.2344 9.56035 14.972 9.56035 14.6484C9.56035 14.3248 9.29801 14.0625 8.97441 14.0625Z"
        fill="#00A0E3" />
    </svg>
  )
}
