import React, { FC, useRef, ChangeEvent } from 'react'
import { UploadIcon } from 'uiKit/icons/UploadIcon'
import { TrashIcon } from 'uiKit/icons/Icons'
import Tooltip from 'uiKit/Tooltip'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { CarePlan } from 'models/ServiceUsersTypes'
import {
  CarePlanItem,
  CarePlansContainer,
  CarePlansFileInput,
  CarePlansList,
  CarePlansUploadButton,
  DeleteAction,
  ItemActionContainer,
  ItemName,
  NoItems,
  OpenInNewWindowAction,
  Divider
} from './CarePlans.styles'
interface Props {
  carePlans: CarePlan[]
  onDeleteAction: (id: string) => void
  onUploadAction: (e: ChangeEvent<HTMLInputElement>) => void
  onOpenInNewWindowAction: (link: string) => void
}

const CarePlans: FC<Props> = ({
  carePlans,
  onDeleteAction,
  onUploadAction,
  onOpenInNewWindowAction
}) => {
  const uploadInputRef = useRef(null)

  const handleUploadOnClick = () => {
    uploadInputRef.current.click()
  }

  return (
    <CarePlansContainer>
      <CarePlansList>
        {carePlans?.length ? (
          carePlans.map((plan, index) => {
            const isLastItem = index === carePlans.length - 1
            return (
              <>
                <CarePlanItem key={plan._id}>
                  <ItemName
                    data-for={`file-${plan._id}`}
                    data-tip
                  >{ plan.originalFilename }
                  </ItemName>
                  <Tooltip id={`file-${plan._id}`}>{ plan.originalFilename }</Tooltip>
                  <ItemActionContainer>
                    <DeleteAction
                      data-for={`delete-${plan._id}`}
                      data-tip
                      onClick={() => onDeleteAction(plan._id)}
                    >
                      <TrashIcon/>
                    </DeleteAction>
                    <Tooltip id={`delete-${plan._id}`}>Delete</Tooltip>
                    <OpenInNewWindowAction
                      data-for={`open-${plan._id}`}
                      data-tip
                      onClick={() => onOpenInNewWindowAction(plan.path)}
                    >
                      <ExternalLinkIcon />
                    </OpenInNewWindowAction>
                    <Tooltip id={`open-${plan._id}`}>Open in new window</Tooltip>
                  </ItemActionContainer>
                </CarePlanItem>
                {!isLastItem && (
                  <Divider />
                )}
              </>
            )
          })
        ) : (
          <NoItems>Attach file(s)</NoItems>
        )}
      </CarePlansList>
      <CarePlansUploadButton onClick={handleUploadOnClick}>
        <UploadIcon />
      </CarePlansUploadButton>
      <CarePlansFileInput
        ref={uploadInputRef}
        onChange={onUploadAction}
        onClick={(event) => event.target.value = null}
      />
    </CarePlansContainer>
  )
}

export default CarePlans
