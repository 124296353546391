import React from 'react'

import { KnowledgeBaseReplies } from 'tabs/nlp/components/KnowledgeBaseReplies/KnowledgeBaseReplies'
import KnowledgeBase from '../tabs/nlp/components/knowledgeBase/KnowledgeBase'

type nlpRoute = {
  path: string
  name: string
  component: () => JSX.Element
}

export const nlpRoutes: nlpRoute[] = [
  {
    path: 'ai-knowledge',
    name: 'AI Knowledge',
    component: () => <KnowledgeBase />,
  },
  {
    path: 'ai-knowledge-replies',
    name: 'AI Knowledge replies',
    component: () => <KnowledgeBaseReplies />,
  },
]
