import styled from 'styled-components'

const LoaderWrap = styled.div`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextFormWrap = styled.div<{ height: number }>`
  width: 650px;
  height: ${({ height }) => height};
  position: absolute;
  background: var(--color-white);
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.086);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

const TitleIconWrap = styled.span`
  padding-left: 8px;
`

const SelectionDiv = styled.div`
    width: 30%;
    z-index: 10;
    position: relative;
    padding-left: 24px;

`

const SubHeader = styled.div`
    font-size: 16px;
    color: var(--color-text-secondary);
    font-weight: bold;
    margin-bottom: 5px;

`

export { TextFormWrap, LoaderWrap, TitleIconWrap, SelectionDiv, SubHeader }
