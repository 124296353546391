import styled from 'styled-components'

const WebsiteTab = styled.div`
  
`

const SubHeader = styled.div`
  font-size: 16px;
  color: var(--color-text-secondary);
  font-weight: bold;
  margin-bottom: 5px;
  `
const SelectionDiv = styled.div`
  width: 30%;
`

export {WebsiteTab, SelectionDiv, SubHeader}
