import styled from 'styled-components'
import { UserStatuses } from '../../helpers/constants'

const statusColorHandler = (status) => {
  return status === UserStatuses.SENT ? 'var(--color-text-placeholder)' : ' var(--color-text-error)'
}

export const UserEmailContainer = styled.div`

`

export const UserEmail = styled.div`

`
export const UserStatus = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${props => statusColorHandler(props.status)};
`
