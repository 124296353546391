import React, { FC, useMemo } from 'react'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import * as S from '../../../nlp/components/knowledgeBase/UploadForm/UploadForm.style'
import {
  Button,
  ButtonContainer, ButtonTitle,
  DownloadButton, IconContainer,
  LoaderContainer,
  UploadContainer,
  UploadTitle,
} from './InviteUserModal.styles'
import { UploadingStatuses } from '../../helpers/constants'

import { FileIcon } from '../../../../uiKit/icons/FileIcon'

interface Props {
  onUpload: (e: Event) => void
  isUploading: boolean
  uploadingStatus: UploadingStatuses
  fileName: string
  title?: string
  template: string
  errorText?: string
}

export const MultipleUsersTab: FC<Props> = ({
  onUpload,
  isUploading,
  uploadingStatus,
  fileName,
  title,
  template,
  errorText
}) => {
  const uploadTitle = useMemo(() => {
    switch (uploadingStatus) {
      case UploadingStatuses.SUCCESS:
        return 'File successfully uploaded. Users have been added to the list.'
      case UploadingStatuses.ERROR:
        // eslint-disable-next-line max-len
        return errorText || 'Invalid file format or data. Please ensure your document: \n' +
          '- is in .csv format; \n' +
          '- has email, role, first and last names filled for every user; \n' +
          '- has no typos in emails, and every email is unique. \n'
      default:
        return title || 'File should be in format of four columns: “Email”, “First Name”, “Second Name” and “Role”.'
    }
  }, [uploadingStatus])

  const buttonTitle = useMemo(() => {
    return isUploading || uploadingStatus === UploadingStatuses.SUCCESS ?
      fileName : 'Upload CSV file'
  }, [isUploading, uploadingStatus, fileName])

  const downloadButtonHandler = () => {
    const link = document.createElement('a')

    link.setAttribute('download', 'Template.csv')
    link.href = template
    link.target = '_self'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <UploadContainer>
      <ButtonContainer>
        <Button
          isUploading={isUploading}
          hasSuccessStatus={uploadingStatus === UploadingStatuses.SUCCESS}
          htmlFor="upload"
          disabled={isUploading || uploadingStatus === UploadingStatuses.SUCCESS}
        >
          {isUploading && (
            <LoaderContainer>
              <LoaderSmall showLoader />
            </LoaderContainer>
          )}
          {uploadingStatus === UploadingStatuses.SUCCESS && (
            <IconContainer>
              <FileIcon />
            </IconContainer>
          )}
          <ButtonTitle>
            {buttonTitle}
          </ButtonTitle>
        </Button>
      </ButtonContainer>
      <S.File
        id="upload"
        type="file"
        multiple="{true}"
        accept=".csv"
        onChange={onUpload}
        onClick={event => {
          event.target.value = null
        }}
      />
      <UploadTitle
        uploadingStatus={uploadingStatus}
        hasError={uploadingStatus === UploadingStatuses.ERROR}
      >
        {uploadTitle}
        {uploadingStatus !== UploadingStatuses.SUCCESS &&
          <DownloadButton onClick={downloadButtonHandler}>
            Download template file
          </DownloadButton>
        }
      </UploadTitle>
    </UploadContainer>
  )
}
