import styled from 'styled-components'
import { Title as TitleBase } from '../DeleteServiceUserModal/DeleteServiceUserModal.styles'

export const Container = styled.div`
  position: absolute;
  background-color: var(--color-white);
  padding: 40px;
  outline: none;
  width: 450px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled(TitleBase)`
  font-weight: 700;
`

export const Subtitle = styled(TitleBase)`
  margin-bottom: 0;
`
