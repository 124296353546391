import styled from 'styled-components'

export const CarePlansContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  margin-right: 24px;
`

export const CarePlansList = styled.ul`
  border-radius: 8px;
  border: 1px solid var(--color-mischka-grey);
  background-color: rgb(var(--color-light));
  padding: 9px 8px 9px 16px;
  margin: 0 8px 0 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 46px;
  max-height: 160px;
  overflow-y: auto;
`

export const CarePlanItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ItemName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
`

export const ItemActionContainer = styled.div`
  display: flex;
`

export const DeleteAction = styled.button`
  padding: 8px;
  border: none;
  background: transparent;
  
  & svg {
    display: block;
    width: 12px;
    height: 12px;
    
    & path {
      stroke: var(--color-pale-sky);
    }
  }
`

export const OpenInNewWindowAction = styled(DeleteAction)``

export const NoItems = styled.li`
  list-style-type: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: var(--color-text-placeholder);
`

export const CarePlansUploadButton = styled.button`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), var(--White-label-color-primary, #00A0E3);
  border-radius: 10px;
  border: none;
  padding: 13px;
  
  & svg {
    display: block;
    
    & path {
      stroke: rgb(var(--color-light));
    }
  }
`

export const Divider = styled.hr`
    border-top: 1px solid #202124;
    border-radius: 1px;
    height: 1px;
    margin: 7px 0 7px 0;
    max-height: 1px;
`

export const CarePlansFileInput = styled.input.attrs(() => ({
  type: 'file',
  name: 'attachment file',
  hidden: true,
  accept: '.pdf, .txt, .doc, .docx',
  multiple: true
}))``
