import React, { useEffect, useState } from 'react'

import * as S from './FileTab.style'
import { UploadForm } from '../UploadForm/UploadForm'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'
import {
  deleteKnowledgeSources,
  getKnowledgeSources,
  trainKnowledgeSources,
  updateFileKnowledgeSources,
} from 'tabs/nlp/api/websiteKnowledgeBase'
import { alertSuccess, alertError, alertWarning } from 'api'
import SourcesTable from '../SourcesTable'
import { ONE_MB } from 'constants/dataSize'
import { onTrainSuccessAlertMessage } from '../TextTab/TextTab'
import Select from 'react-select'
import { customStyles } from '../SettingsTab/SettingsTab.style'

export const FileTab = ({ activeBot }) => {
  const [controller, setController] = useState(new AbortController())
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(TrainingStatus.PENDING)
  const [notTrainedFileLimitAchieved, setNotTrainedFileLimitAchieved] = useState(true)
  const [isUploading, setIsUploading] = useState(false)
  const [level, setLevel] = useState('GENERIC_DOC')

  const FILE_SIZE_LIMIT = ONE_MB * 100
  const NOT_TRAINED_FILE_LIMIT = 50

  useEffect(() => {
    getKnowledgeSources(activeBot.id, 'FILE').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === TrainingStatus.PENDING)
        ? TrainingStatus.PENDING
        : TrainingStatus.READY
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
    return () => {
      controller.abort()
      setController(new AbortController())
    }
  }, [])

  useEffect(() => {
    setNotTrainedFileLimitAchieved(countNotTrainedSources() >= NOT_TRAINED_FILE_LIMIT)
  }, [sources])

  const countNotTrainedSources = () => sources.filter(source => source.status !== TrainingStatus.READY).length

  const handleUpload = event => {
    setIsUploading(true)
    const invalidFilesMessages = []
    const validFiles = validateFiles([...event.target.files], invalidFilesMessages)
    const totalFileCount = event.target.files.length
    if (validFiles.length > 0) {
      setTrainingStatus(TrainingStatus.UPLOADING)
      uploadFileKnowledgeSources(validFiles, level).finally(() =>
        completeUploading(validFiles.length, totalFileCount, invalidFilesMessages),
      )
    } else {
      completeUploading(validFiles.length, totalFileCount, invalidFilesMessages)
    }
  }

  const completeUploading = (validFileCount, totalFileCount, invalidFilesMessages) => {
    setTrainingStatus(TrainingStatus.READY)
    setIsUploading(false)
    showFilesAlert(validFileCount, totalFileCount, invalidFilesMessages)
  }

  const getFileUploadingLimit = () => NOT_TRAINED_FILE_LIMIT - countNotTrainedSources()

  const validateFiles = (files, invalidFilesMessages) => {
    const validFiles = files.filter(file => isValidFile(file, invalidFilesMessages))
    const takenFiles = filterFilesWithinSizeLimit(validFiles)
    if (takenFiles.length < validFiles.length) {
      invalidFilesMessages.push('The total size of the files is too big.')
    }
    return takenFiles.slice(0, getFileUploadingLimit())
  }

  const isValidFile = (file, invalidFileMessages) => {
    const isNotUniqueFile = sources.some(source => source.originalFilename === file?.name)
    const isTooBig = file?.size > FILE_SIZE_LIMIT
    if (isNotUniqueFile) {
      invalidFileMessages.push(`File '${file?.name}' is already added.`)
      return false
    } else if (isTooBig) {
      invalidFileMessages.push(`File '${file?.name}' is too big to upload.`)
      return false
    }
    return true
  }

  const filterFilesWithinSizeLimit = files => {
    let totalByteSize = 0
    return files.filter(file => {
      const mayBeAdded = totalByteSize + file.size <= FILE_SIZE_LIMIT
      if (mayBeAdded) {
        totalByteSize += file.size
      }
      return mayBeAdded
    })
  }

  const uploadFileKnowledgeSources = (validFiles, level) => {
    const formData = prepareFormData(validFiles, level)
    return updateFileKnowledgeSources(activeBot.id, formData, controller.signal, level)
      .then(res => {
        setSources([...sources, ...res])
      })
      .catch(error => {
        alertError(error?.message || 'Something went wrong')
      })
  }

  const prepareFormData = (files, level) => {
    const formData = new FormData()
    const newSources = []
    files.forEach(file => {
      formData.append('files', file)

      const newSource = {
        originalFilename: file?.name,
        status: 'NOT_TRAINED',
        type: 'FILE',
        isUploaded: false,
        hierarchyLevel: level,
      }
      newSources.push(newSource)
    })
    setSources([...sources, ...newSources])
    return formData
  }

  const showFilesAlert = (uploadedFileCount, totalFileCount, invalidFilesMessages) => {
    if (uploadedFileCount < totalFileCount) {
      const message = buildFilesErrorMessage(uploadedFileCount, totalFileCount, invalidFilesMessages)
      if (uploadedFileCount > 0) {
        alertWarning(message)
      } else {
        alertError(message)
      }
    } else {
      alertSuccess('Files added successfully')
    }
  }

  const buildFilesErrorMessage = (uploadedFileCount, totalFileCount, invalidFilesMessages) => {
    const limitExceeded =
      totalFileCount > getFileUploadingLimit() ? `Maximum ${NOT_TRAINED_FILE_LIMIT} untrained files are allowed.` : ''
    return (
      `Files uploaded: ${uploadedFileCount}/${totalFileCount}` + '. ' + limitExceeded + invalidFilesMessages.join(' ')
    )
  }

  const handleDelete = source => {
    if (source.isUploaded === false) {
      controller.abort()
      setController(new AbortController())
      const sourcesCopy = sources.filter(x => x !== source)
      setSources(sourcesCopy)
      return new Promise(() => alertSuccess('Uploading is canceled successfully'))
    }
    return deleteKnowledgeSources(activeBot.id, source._id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source._id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('File is deleted successfully'))
  }

  const handleTrain = () => {
    const body = sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id)

    trainKnowledgeSources(activeBot.id, body)
      .then(() => {
        setTrainingStatus(TrainingStatus.PENDING)
        alertSuccess(onTrainSuccessAlertMessage)
      })
      .catch(error => alertError(error?.message || 'Something went wrong'))
  }

  const getUploadButtonTooltip = () => {
    return notTrainedFileLimitAchieved
      ? `You can only train ${NOT_TRAINED_FILE_LIMIT} files at once. Train the current files to proceed.`
      : null
  }

  const options = [
    { value: 'FAQS_FLOWS', label: 'FAQs/flows' },
    { value: 'COMPANY_DOC', label: 'Careco docs' },
    { value: 'GENERIC_DOC', label: 'Generic' },
  ]


  const defaultOption = { value: 'GENERIC_DOC', label: 'Generic' }


  const handleChange = (selectedOption) => {
    setLevel(selectedOption.value)
  }

  return (
    <S.FileTab>
      <S.SelectionDiv>
        <S.SubHeader>Knowledge Type</S.SubHeader>
        <Select
          styles={customStyles}
          options={options}
          onChange={handleChange}
          defaultValue={defaultOption}
        />
      </S.SelectionDiv>
      <UploadForm
        onUpload={handleUpload}
        isUploading={isUploading}
        buttonActive={!notTrainedFileLimitAchieved && trainingStatus !== TrainingStatus.PENDING}
        buttonTooltip={getUploadButtonTooltip()}
      />
      <SourcesTable sources={sources} onDelete={handleDelete} onTrain={handleTrain} trainingStatus={trainingStatus} />
    </S.FileTab>
  )
}
