import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import LoaderOverlay from '../../../../../uiKit/loaders/loaderOverlay'

import * as S from './UploadForm.style'


export const UploadForm = ({ onUpload, isUploading, buttonActive, buttonTooltip }) => {
  const BUTTON_TITLE = 'Upload files'

  const renderButton = () => {
    return buttonActive ? (
      <S.Button htmlFor="upload">{BUTTON_TITLE}</S.Button>
    ) : (
      <S.DisabledButton>{BUTTON_TITLE}</S.DisabledButton>
    )
  }

  const renderButtonWithTooltip = () => {
    return buttonTooltip ? (
      <Tooltip title={buttonTooltip} placement="top">
        {renderButton()}
      </Tooltip>
    ) : (
      renderButton()
    )
  }


  return (
    <S.UploadForm>
      {isUploading ? (
        <>
          {renderButtonWithTooltip()}
          <LoaderOverlay />
        </>
      ) : (
        renderButtonWithTooltip()
      )}

      <S.File
        id="upload"
        type="file"
        multiple="{true}"
        accept=".pdf, .txt, .doc, .docx"
        onChange={onUpload}
        onClick={event => {
          event.target.value = null
        }}
      />
      {/* onClick guarantees that onChange event always fires, even if the same file is uploaded twice in a row */}

      <S.Title>Files should be PDF, TXT, Doc, Docx</S.Title>
    </S.UploadForm>
  )
}
