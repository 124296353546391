export enum UserStatuses {
  EXPIRED = 'EXPIRED',
  SENT = 'SENT'
}

export enum TimeframeFormValues {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  ALL_TIME = 'all_time',
  CUSTOM = 'custom',
}

export enum UploadingStatuses {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
