import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import DialogsContainer from './components/DialogsContainer/DialogsContainer'
import ChatContainer from './components/ChatContainer/ChatContainer'
import NoChatScreen from './components/NoChatScreen/NoChatScreen'

import { HOME_PATH } from '../../configs'
import { isUrlEndsWithSupport } from '../../helpers/isUrlEndsWithSupport'
import { getFlows } from '../flowBuilder/api/flow'
import { styles } from './styles'
import { getBot } from '../home/api/bots'
import { connect } from 'react-redux'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'

class SupportContainer extends Component {
  componentDidMount() {
    getFlows(this.props.match.params.botId)
    getBot(this.props.match.params.botId)
    setBrowserTabTitle(`${this.props.activeBot?.name} - Support`)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeBot?.name !== this.props.activeBot?.name) {
      setBrowserTabTitle(`${this.props.activeBot?.name} - Support`)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container} style={{ margin: '40px 40px 70px 0px', height: 'calc(100% - 110px)' }}>
        <DialogsContainer />

        {isUrlEndsWithSupport() && <NoChatScreen />}

        <Route
          path={HOME_PATH + '/bot/:botId/support/:userId/:chatId'}
          render={(props) => <ChatContainer {...props} />}
        />
      </div>
    )
  }
}

SupportContainer.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = ({ activeBot }) => ({ activeBot })

export default withStyles(styles)(connect(mapStateToProps)(SupportContainer))
