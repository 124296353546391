import styled from 'styled-components'
import { ATHENS_GRAY, ATHENS_GRAY_DARK } from 'constants/colors'

export const CarersSelectMenuHeader = styled.div`
  padding: 8px 16px;
  border-radius: 10px 10px 0 0;
  background-color: ${ATHENS_GRAY};
  border-bottom: 1px solid ${ATHENS_GRAY_DARK};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  & > div {
    margin: 3px auto 2px 0;
    line-height: 16px;
    padding-left: 26px;
  }
  
  & span {
    width: 16px;
    height: 16px;
    
    &:after {
      left: 4px !important;
      top: 0 !important;
      width: 6px !important;
      height: 11px !important;
    }
  }
`

export const ActionButton = styled.button`
  border: none;
  border-radius: 6px;
  margin-right: 8px;
  padding: 7px;
  background: ${props => props.backgroundColor || 'linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), var(--White-label-color-primary, #00A0E3)'};
  color: rgb(var(--color-light));
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(1) {
    margin-left: auto;
  }
    
  &:disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), var(--static-text-placeholder, #ACB0C0);
    cursor: auto;
  }
`

export const CarersSelectTotalChecked = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: var(--color-text-primary);
  padding: 8px 26px;
`
