import React, { useRef, useEffect } from 'react'
import xss from 'xss'

import { TextMessageType } from 'models/ChatMessagesTypes'
import * as S from './TextMessage.style'
import linkEditorListener from '../../helpers/linkEditorListener'
import { xssOptions } from 'constants/xssOptions'


interface Props {
  message: TextMessageType
  isReceived: boolean
  isRtl: boolean
  highlight: boolean
  searchQuery: string
}

export const TextMessage: React.FC<Props> = props => {
  const { message, searchQuery } = props

  const output = useRef(null)

  useEffect(() => {
    linkEditorListener(output)
  }, [])

  const highlightText = (text, query) => {
    if (!query || !text) return text

    if (query === text) {
      return `<span style="background-color: #ed5e79;text-decoration:none;">${text}</span>`
    } else {
      const escapedQuery = escapeRegExp(query)
      const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'))
        return parts
          .map(part => {
            return part.toLowerCase() === query.toLowerCase()
              ? `<span style="background-color: #ed5e79;text-decoration:none;">${part}</span>`
              : part
          })
          .join('')
    }
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }


  const messageContent = message.text || message.postback

  const highlightedContent = highlightText(messageContent, searchQuery)

  const sanitizedContent = xss(highlightedContent, xssOptions)

  return (
    <S.TextMessage
      ref={output}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      {...props}
    />
  )
}
