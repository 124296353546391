import React, { FC, useState } from 'react'
import { useStore } from 'react-redux'
import Popover from '@material-ui/core/Popover'
import { VerticalThreeDots } from 'uiKit/icons/VerticalThreeDots'
import { UserType } from '../../model/usersModel'
import { getUsers, resendUserInvitation, resetUserPassword } from '../../api/usersApi'
import {
  ActionSelectorButton,
  ActionSelectorContainer,
  PopoverButton
} from './ActionSelector.styles'
import classes from './styles.module.scss'

interface Props {
  user: UserType
  botId: string
  openDeleteModalHandler: (user: UserType) => void
}

export const ActionSelector: FC<Props> = ({ user, botId, openDeleteModalHandler }) => {
  const store = useStore()
  const { usersTab: { currentPage } } = store.getState()
  const [isOpenActionSelector, setOpenActionSelector] = useState(false)
  const [target, setTarget] = useState(null)

  const resendInviteAction = () => {
    setOpenActionSelector(false)
    resendUserInvitation(user.id).then(() => {
      getUsers(botId, currentPage)
    })
  }

  const resetPasswordAction = () => {
    setOpenActionSelector(false)
    resetUserPassword(user.id)
  }

  const deleteUserAction = () => {
    setOpenActionSelector(false)
    openDeleteModalHandler(user)
  }

  return (
    <ActionSelectorContainer>
      <ActionSelectorButton
        onClick={() => setOpenActionSelector(true)}
        ref={node => setTarget(node)}
      >
        <VerticalThreeDots />
      </ActionSelectorButton>
      <Popover
        classes={{ paper: classes.popover }}
        open={isOpenActionSelector}
        anchorEl={target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setOpenActionSelector(prevState => !prevState)}
      >
        {user.link_status.toLowerCase() === 'expired' && (
          <PopoverButton onClick={resendInviteAction}>
            Resend Invite
          </PopoverButton>
        )}
        <PopoverButton onClick={resetPasswordAction}>
          Reset Password
        </PopoverButton>
        <PopoverButton onClick={deleteUserAction}>
          Delete
        </PopoverButton>
      </Popover>
    </ActionSelectorContainer>
  )
}
