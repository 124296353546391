import styled from 'styled-components'

export const ActionSelectorContainer = styled.div`
  width: 100%;
`

export const ActionSelectorButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  
  & svg {
    display: block;
  }
`

export const PopoverButton = styled.button`
  padding: 16px;
  border: none;
  background-color: transparent;
  color: var(--color-text-primary);
  display: block;
  width: 100%;
  text-align: left;
  
  &:hover {
    background-color: var(--color-hover-overlay-primary);
  }
  
  &:last-child {
    color: var(--color-text-error)
  }
`
