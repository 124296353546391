import React, { FC, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { debounce } from 'lodash'
import { Button } from '@material-ui/core'
import Headline from 'uiKit/texts/Headline'
import NoChartData from 'uiKit/NoChartData'
import Search from 'uiKit/Search/Search'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import Pagination from 'uiKit/table/Pagination'
import { DEFAULT_DEBOUNCE_TIME } from 'constants/time'
import { ServiceUserType } from 'models/ServiceUsersTypes'
import { AddNewServiceUserModal } from './components/AddNewServiceUserModal/AddNewServiceUserModal'
import { deleteUsers, getAllCareProfessionals, getServiceUsers } from './api/serviceUsersApi'
import { ServiceUsersTable } from './components/ServiceUsersTable/ServiceUsersTable'
import {
  clearSelectedServiceUsersId,
  setIsLoadingServiceUsers,
  updateCurrentPage,
  updateRowsPerPage,
} from './actions/serviceUsersActions'
import { inputSearchStyles, buttonStyles } from '../users/Users'

import {
  ServiceUsersButtonWrap,
  ServiceUsersContainer,
  ServiceUsersControlLine,
  ServiceUsersHeadlineWrap,
  ServiceUsersSearchWrap,
  ButtonTitle
} from './ServiceUsers.styles'
import { DeleteServiceUserModal } from './components/DeleteServiceUserModal/DeleteServiceUserModal'
import DuplicateAttentionModal from './components/DuplicateAttentionModal/DuplicateAttentionModal'

interface Props {
  serviceUsers: ServiceUserType[]
  currentPage: number
  totalElements: number
  match: any
  isLoadingServiceUsers: boolean
  selectedServiceUsersId: number[]
  rowsPerPage: number
}

const ServiceUsers: FC<Props> = ({
  serviceUsers,
  currentPage,
  totalElements,
  match,
  isLoadingServiceUsers,
  selectedServiceUsersId,
  rowsPerPage
}) => {
  const { params: { botId } } = match
  const [searchingValue, setSearchingValue] = useState('')
  const [isAddNewServiceUserModalOpen, setIsAddNewServiceUserModalOpen] = useState(false)
  const [duplicateAttentionModal, setDuplicateAttentionModal] = useState({
    isOpen: false,
    duplicateUsers: ''
  })
  const [deleteUserModal, setDeleteUserModal] = useState({
    isOpen: false,
    isSingleDelete: false,
    selectedUser: null
  })

  useEffect(() => {
    setIsLoadingServiceUsers(true)
    if (typeof rowsPerPage === 'string') {
      rowsPerPage = totalElements
    }
    Promise.all([
      getServiceUsers(botId, currentPage, searchingValue, rowsPerPage),
      getAllCareProfessionals(botId),
    ]).then(() => {
      setIsLoadingServiceUsers(false)
    })
  }, [currentPage, searchingValue, rowsPerPage])

  useEffect(() => {
    return () => {
      updateCurrentPage(0)
      updateRowsPerPage(10)
    }
  }, [])

  const searchingByValue = useRef(debounce((value) => {
      getServiceUsers(botId, 0, value, rowsPerPage)
    }, DEFAULT_DEBOUNCE_TIME)
  )

  const onChangeHandler = (e) => {
    const value = e.target.value
    setSearchingValue(value)
    updateCurrentPage(0)
    searchingByValue.current(value)
  }

  const onChangePageHandler = (e, page) => {
    updateCurrentPage(page)
    getServiceUsers(botId, page, searchingValue, rowsPerPage)
  }

  const onChangeRowsPerPageHandler = (event) => {
    const value = event.target.value
    updateRowsPerPage(value)
    updateCurrentPage(0)
    const rows = value === 'All' ? totalElements : parseInt(value)
    getServiceUsers(botId, 0, searchingValue, rows)
  }


  const onCloseDeleteModalHandler = () => {
    setDeleteUserModal({
      isOpen: false,
      isSingleDelete: false,
      selectedUser: null
    })
  }

  const deleteUsersHandler = () => {
    const selectedUsersId = deleteUserModal.isSingleDelete ? deleteUserModal.selectedUser.id : selectedServiceUsersId.join(',')
    setDeleteUserModal(prevState => ({
      ...prevState,
      isSingleDelete: false,
      isOpen: false
    }))
    deleteUsers(botId, selectedUsersId).then(() => {
      getServiceUsers(botId, currentPage)
      setDeleteUserModal(prevState => ({
        ...prevState,
        isSingleDelete: false,
        selectedUser: null
      }))
      !!selectedServiceUsersId.length && clearSelectedServiceUsersId()
    })
  }


  return (
    <ServiceUsersContainer>
      <ServiceUsersHeadlineWrap>
        <Headline title='Service users' />
      </ServiceUsersHeadlineWrap>
      <ServiceUsersControlLine>
        <ServiceUsersSearchWrap>
          <Search
            value={searchingValue}
            onChange={onChangeHandler}
            placeholder='Search by service user'
            inputStyle={inputSearchStyles}
          />
        </ServiceUsersSearchWrap>
        <ServiceUsersButtonWrap>
          <Button
            style={buttonStyles}
            onClick={() => setIsAddNewServiceUserModalOpen(true)}
          >
            <ButtonTitle>Add new service users</ButtonTitle>
          </Button>
        </ServiceUsersButtonWrap>
      </ServiceUsersControlLine>

      {isLoadingServiceUsers ? (
        <LoaderScreen />
      ) : (
        <>
          {serviceUsers.length > 0 ? (
            <>
              <ServiceUsersTable
                botId={botId}
                setDeleteUserModal={setDeleteUserModal}
                searchValue={searchingValue}
                page={currentPage}
                rowsPerPage={rowsPerPage}
              />
              <Pagination
                page={currentPage}
                rowsPerPage={rowsPerPage}
                count={totalElements}
                onChangePage={onChangePageHandler}
                onChangeRowsPerPage={onChangeRowsPerPageHandler}
                hasSelect={true}
                labelRowsPerPage='Show'
              />
            </>
          ) : (
            <NoChartData
              isUserData={true}
              text='You have no Service users yet. Start by pressing the button -  Add new service users'
            />
          )}
        </>
      )}

      {isAddNewServiceUserModalOpen &&
        <AddNewServiceUserModal
          botId={botId}
          isAddNewServiceUserModalOpen={isAddNewServiceUserModalOpen}
          onClose={() => setIsAddNewServiceUserModalOpen(false)}
          setDuplicateAttentionModal={setDuplicateAttentionModal}
        />
      }
      {deleteUserModal.isOpen &&
        <DeleteServiceUserModal
          open={deleteUserModal.isOpen}
          selectedUser={deleteUserModal.selectedUser}
          onClose={onCloseDeleteModalHandler}
          onDelete={deleteUsersHandler}
        />
      }

      {duplicateAttentionModal.isOpen &&
        <DuplicateAttentionModal
          open={duplicateAttentionModal.isOpen}
          userNames={duplicateAttentionModal.duplicateUsers}
          onClose={() => setDuplicateAttentionModal(prevState => ({ ...prevState, isOpen: false}))}
        />
      }
    </ServiceUsersContainer>
  )
}

const mapStateToProps = (state) => ({
  serviceUsers: state.serviceUsersTab.serviceUsers,
  currentPage: state.serviceUsersTab.currentPage,
  totalElements: state.serviceUsersTab.totalElements,
  isLoadingServiceUsers: state.serviceUsersTab.isLoadingServiceUsers,
  careProfessionals: state.serviceUsersTab.careProfessionals,
  selectedServiceUsersId: state.serviceUsersTab.selectedServiceUsersId,
  rowsPerPage: state.serviceUsersTab.rowsPerPage
})

export default withRouter(connect(mapStateToProps)(ServiceUsers))
