import React, { FC, useState } from 'react'
import { useStore } from 'react-redux'
import Select from 'react-select'
import SelectorStyles from 'tabs/nlp/constants/selectorStyles'
import { getUsers, updateUser } from '../../api/usersApi'
import { UserType } from '../../model/usersModel'

export const roleOptions = [
  {
    label: 'Carer',
    value: 'CARER'
  },
  {
    label: 'Supervisor',
    value: 'SUPERVISOR'
  }
]

interface Props {
  value: string
  user: UserType
  botId: string
}

export const RoleSelector: FC<Props> = ({
  value,
  user,
  botId
}) => {
  const store = useStore()
  const { usersTab: { currentPage } } = store.getState()
  const [selectedValue, setSelectedValue] = useState({
    label: value.charAt(0) + value.substring(1).toLowerCase(),
    value
  })

  const onChangeHandler = (selectedValue) => {
    const userData = {
      ...user,
      role: selectedValue.value
    }
    setSelectedValue(selectedValue)
    updateUser(userData).then(() => {
      getUsers(botId, currentPage)
    })
  }

  return (
    <Select
      isSearchable={false}
      options={roleOptions}
      value={selectedValue}
      onChange={onChangeHandler}
      styles={{
        ...SelectorStyles,
        indicatorSeparator: provided => ({
          ...provided,
          display: 'none',
        })
      }}
    />
  )
}
