import React from 'react'
import { CheckboxContainer, CheckboxInput, Checkmark } from './Checkbox.styles'

interface Props {
  color?: string
  checked: boolean
  onChange: (a: boolean) => void
  label?: string
}

const Checkbox: React.FC<Props> = ({ color, checked, onChange, label }) => {
  const style: { [key: string]: string } = {
    '--theme-color': color || 'var(--color-button-secondary)',
  }

  const handleChange = () => {
    onChange && onChange(!checked)
  }

  return (
    <CheckboxContainer onClick={handleChange}>
      <CheckboxInput checked={checked} onChange={handleChange} />
      <Checkmark style={style} />
    </CheckboxContainer>
  )
}

export default Checkbox
