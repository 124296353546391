import styled from 'styled-components'

const TextTab = styled.div`
  
`
const SubHeader = styled.div`
  font-size: 16px;
  color: var(--color-text-secondary);
  font-weight: bold;
  margin-bottom: 5px;
  `
const SelectionDiv = styled.div`
  width: 30%;
  z-index: 10;
  position: relative;
`

export {TextTab, SelectionDiv, SubHeader}
