import React, { useEffect, useMemo, useState } from 'react'

import WebsiteURLForm from '../WebsiteURLForm'
import SourcesTable from '../SourcesTable'
import {
  deleteKnowledgeSources,
  getKnowledgeSources,
  trainKnowledgeSources,
  updateWebsiteKnowledgeSources,
  SourceTypeMap
} from 'tabs/nlp/api/websiteKnowledgeBase'
import { alertSuccess, alertError } from 'api'

import * as S from './WebsiteTab.style'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'
import normalizeUrl from 'normalize-url'
import { onTrainSuccessAlertMessage } from '../TextTab/TextTab'
import Select from 'react-select'
import { customStyles } from '../SettingsTab/SettingsTab.style'

export const WebsiteTab = ({ activeBot }): JSX.Element => {
  const [sources, setSources] = useState([])
  const [trainingStatus, setTrainingStatus] = useState(TrainingStatus.PENDING)
  const urls = useMemo(() => sources.map(x => x.url), [sources])
  const [level, setLevel] = useState('GENERIC_DOC')

  useEffect(() => {
    fetchKnowledgeSources()
  }, [])

  const fetchKnowledgeSources = async (): Promise<void> => {
    getKnowledgeSources(activeBot.id, 'WEBSITE').then(res => {
      const trainingStatus = res?.sources?.some(source => source.status === TrainingStatus.PENDING)
        ? TrainingStatus.PENDING
        : TrainingStatus.READY
      setSources(res?.sources || [])
      setTrainingStatus(trainingStatus)
    })
  }

  const handleAdd = url => {
    url = normalizeUrl(url, { forceHttps: true, stripWWW: false, removeTrailingSlash: true })
    const body = { url, type: SourceTypeMap.WEBSITE, hierarchyLevel: level }
    updateWebsiteKnowledgeSources(activeBot.id, body)
      .then(res => {
        setSources([...sources, res])
      })
      .then(() => alertSuccess('Website is added successfully'))
      .catch(error => alertError(error?.message || 'Something went wrong'))
  }

  const handleDelete = source => {
    const source_id = source._id
    return deleteKnowledgeSources(activeBot.id, source_id)
      .then(() => {
        const sourcesCopy = sources.filter(x => x._id !== source_id)
        setSources(sourcesCopy)
      })
      .then(() => alertSuccess('Website is deleted successfully'))
  }

  const handleTrain = () => {
    const body = sources.filter(x => ['NOT_TRAINED', 'FAILED'].includes(x.status)).map(x => x._id)
    trainKnowledgeSources(activeBot.id, body)
      .then(() => {
        setTrainingStatus(TrainingStatus.PENDING)
        alertSuccess(onTrainSuccessAlertMessage)
      })
      .catch(error => alertError(error?.message || 'Something went wrong'))
  }

  const options = [
    { value: 'FAQS_FLOWS', label: 'FAQs/flows' },
    { value: 'COMPANY_DOC', label: 'Careco docs' },
    { value: 'GENERIC_DOC', label: 'Generic' },
  ]

  const defaultOption = { value: 'GENERIC_DOC', label: 'Generic' }

  const handleChange = (selectedOption) => {
    setLevel(selectedOption.value)
  }

  return (
    <S.WebsiteTab>
      <S.SelectionDiv>
        <S.SubHeader>Knowledge Type</S.SubHeader>
        <Select
          styles={customStyles}
          options={options}
          onChange={handleChange}
          defaultValue={defaultOption}
        />
      </S.SelectionDiv>
      <WebsiteURLForm onClick={handleAdd} urls={urls} trainingStatus={trainingStatus} />
      <SourcesTable
        sources={sources}
        onDelete={handleDelete}
        onTrain={handleTrain}
        trainingStatus={trainingStatus}
        fetchKnowledgeSources={fetchKnowledgeSources}
      />
    </S.WebsiteTab>
  )
}
