import { DateControlContext } from 'contexts/DateControlContext'
import { BotType } from 'models/BotType'
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select, { OptionTypeBase } from 'react-select'
import { KnowledgeBaseReplySource, getKnowledgeBaseReplySources } from 'tabs/nlp/api/knowledgeBaseReplySources'
import { SourceTypeMap } from 'tabs/nlp/api/websiteKnowledgeBase'
import { defaultValueReplySource } from 'tabs/nlp/constants/defaultValues'

interface KnowledgeBaseReplySourceSelectProps {
  activeBot: BotType
  value: OptionTypeBase
  onChange: (value: OptionTypeBase) => void
  styles: any
}

const KnowledgeReplySourceSelect: React.FC<KnowledgeBaseReplySourceSelectProps> = ({
  activeBot,
  value,
  onChange,
  styles,
}) => {
  const { dateRange } = useContext(DateControlContext)
  const [replySources, setReplySources] = useState([])


  useEffect(() => {
    fetchKnowledgeReplySources()
  }, [dateRange])

  const fetchKnowledgeReplySources = () => {
    getKnowledgeBaseReplySources(
      activeBot.id,
      dateRange.startDate.format('YYYY-MM-DD'),
      dateRange.endDate.format('YYYY-MM-DD'),
    ).then(setReplySources)
  }

  const getReplySourceOptions = () => {
    if (replySources) {
      const options = replySources.map(mapReplySourceToOption)
      options.unshift(defaultValueReplySource)
      return options
    }
    return []
  }

  const mapReplySourceToOption = (replySource: KnowledgeBaseReplySource) => {
    return {
      value: replySource.sourceTitle,
      label: getSourceLabel(replySource),
    }
  }

  const getSourceLabel = (replySource: KnowledgeBaseReplySource): string => {
    if (replySource.sourceType === SourceTypeMap.WEBSITE) {
      const url = new URL(replySource.sourceTitle)
      return url.pathname + url.search
    }
    return replySource.sourceTitle
  }

  const options = getReplySourceOptions()

  return <Select value={value} onChange={onChange} options={options} styles={styles}/>
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(KnowledgeReplySourceSelect)
