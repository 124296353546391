import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ChatHeader from '../ChatHeader/ChatHeader'
import MessagesWrap from '../MessagesWrap'

import { styles } from './styles'
import { clearActiveUser } from '../../actions/activeUser'

const ChatContainer = (props) => {
  const { classes, match } = props
  const { chatId } = match.params

  useEffect(() => {
    return () => {
      clearActiveUser()
    }
  }, [])

  return (
    <div className={classes.container}>
      <ChatHeader />
      <MessagesWrap chatId={chatId} />
    </div>
  )
}

ChatContainer.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(ChatContainer))
