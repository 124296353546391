import React, { FC, useMemo, useState } from 'react'
import ModalTemplate from '../../../../uiKit/ModalTemplate'
import classes from '../../../widget/components/AddGreetingModal/styles.module.scss'
import { OneUserTab } from './OneUserTab'
import { MultipleUsersTab } from './MultipleUsersTab'
import { emailValidation } from '../../../../helpers/emailValidation'
import { getUsers, inviteNewUser, uploadFile } from '../../api/usersApi'
import { UploadingStatuses } from '../../helpers/constants'
// @ts-ignore
import Template from '../../helpers/Template.csv'

interface Props {
  botId: string
  isOpenUserModal: boolean
  onClose: () => void
}

type TabType = 'One user' | 'Multiple users'
const tabs: TabType[] = ['One user', 'Multiple users']

export type FormType = {
  first_name: string
  last_name: string
  email: string
  role: string
}

export type ErrorsType = {
  first_name: string
  last_name: string
  email: string
}

export const InviteUserModal: FC<Props> = ({ botId, isOpenUserModal, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingStatus, setUploadingStatus] = useState(UploadingStatuses.PENDING)
  const [fileName, setFileName] = useState('')
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: ''
  })
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: 'CARER'
  })
  const modalTitle = useMemo(() => {
    return `Invite ${selectedTab === tabs[0] ? 'a new professional' : 'multiple professionals'} to CareBrain`
  }, [selectedTab])

  const uploadResponseHandler = (response) => {
    if (response.status === 200) {
      setUploadingStatus(UploadingStatuses.SUCCESS)
      getUsers(botId, 0)
    } else if (response.status === 500) {
      setUploadingStatus(UploadingStatuses.ERROR)
    }
  }

  const handleUpload = (event) => {
    setIsUploading(true)
    const file = event.target.files[0]
    const formData = new FormData()
    if (file) {
      setFileName(file.name)
      formData.append('file', file)
    }

    uploadFile(botId, formData).then(response => {
      setIsUploading(false)
      uploadResponseHandler(response)
    })
  }

  const handleSave = () => {
    if (!form.first_name || form.first_name.trim() == '') {
      setErrors(prevState => ({
        ...prevState,
        first_name: 'First Name can’t be empty'
      }))
    }
    if (!form.last_name || form.last_name.trim() == '') {
      setErrors(prevState => ({
        ...prevState,
        last_name: 'Last Name can’t be empty'
      }))
    }
    if (!emailValidation(form.email)) {
      setErrors(prevState => ({
        ...prevState,
        email: 'Incorrect email format'
      }))
    }

    if (!form.first_name || !form.last_name || !emailValidation(form.email) || form.first_name.trim() == '' || form.last_name.trim() == '') {
      return
    }
    inviteNewUser({ ...form, botId }).then((response) => {
      if (response.status === 200) getUsers(botId, 0)
    })
    onClose()
  }

  return (
    <ModalTemplate
      title={modalTitle}
      open={isOpenUserModal}
      onClose={onClose}
      onSave={handleSave}
      primaryButtonTitle='Invite'
      hasPrimaryButton={selectedTab === tabs[0]}
    >
      <>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => setSelectedTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div
          className={classes.body}
          id='scrollable'
          style={{ height: 386 }}
        >
          {selectedTab === tabs[0] && (
            <OneUserTab
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {selectedTab === tabs[1] && (
            <MultipleUsersTab
              onUpload={handleUpload}
              isUploading={isUploading}
              uploadingStatus={uploadingStatus}
              template={Template}
              fileName={fileName}
            />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}
