import { AnyAction } from 'redux'
import {
  SAVE_TOTAL_PAGES,
  SAVE_TOTAL_ELEMENTS,
  SAVE_USERS,
  SET_IS_LOADING_USERS,
  UPDATE_CURRENT_PAGE
} from '../actions/usersAction'

const initialState = {
  users: [],
  totalPages: 0,
  totalElements: 0,
  currentPage: 0,
  isLoadingUsers: true
}

const usersTab = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SAVE_USERS:
      return {
        ...state,
        users: action.users
      }
    case SAVE_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.totalPages
      }
    case SAVE_TOTAL_ELEMENTS:
      return {
        ...state,
        totalElements: action.totalElements
      }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }
    case SET_IS_LOADING_USERS:
      return {
        ...state,
        isLoadingUsers: action.isLoadingUsers
      }
    default:
      return state
  }
}

export default usersTab
