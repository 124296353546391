import React, { useState } from 'react'

import UserDialogPreview from '../UserDialogPreview/UserDialogPreview'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { SupportUserType } from 'models/SupportUserType'

import * as S from './DialogsSection.styles'

interface Props {
  title: string
  users: SupportUserType[]
  search: string
  loading: boolean
  loadMoreUsers?: any
}

export const DialogsSection: React.FC<Props> = ({ title, users, search, loadMoreUsers, loading }) => {

  const [showButton, setShowButton] = useState(true)

  const handleLoad = () => {
    loadMoreUsers().then(res => {
      if (res?.length < 20) setShowButton(false)
    })
  }

  return (
    <>
      {users && users?.map(user => <UserDialogPreview user={user} key={`${user.userId}-${user.chatId}`} />)}
      {users && users?.length >= 20 && loadMoreUsers && !loading && showButton && (
        <S.LoadMoreButtonWrapper>
          <S.LoadMoreButton onClick={handleLoad}>Load more chats</S.LoadMoreButton>
        </S.LoadMoreButtonWrapper>
      )}
      {loading && <LoaderSmall showLoader={true} />}
    </>
  )
}
