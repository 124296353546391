import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { alertSuccess } from 'api'
import { getTextInsertSource, updateTextInsert, updateTextKnowledgeSources, SourceTypeMap } from 'tabs/nlp/api/websiteKnowledgeBase'
import { SourceTypes, maxTextTitleLength } from 'tabs/nlp/constants'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import ModalTemplate from 'uiKit/ModalTemplate'

import * as S from './EditKnowledgeBaseReply.style'
import classes from './styles.module.scss'
import { TextForm } from '../knowledgeBase/TextForm/TextForm'
import Select from 'react-select'
import { customStyles } from '../knowledgeBase/SettingsTab/SettingsTab.style'

interface EditKnowledgeBaseReplyProps {
  onClose: () => void
  inputTitle: string
  replyId?: number
  setSources?: Dispatch<SetStateAction<any>>
  improvementSourceId?: string
  sourceId?: string
  textAreaContent: string
  isOpen: boolean
  botId: number
  page?: number
  fetchKnowledgeReplies?: (page: number) => void
}

type TextInsertPayload = {
  title: string
  text: string
  type: SourceTypes
}

export type TextInsertSource = {
  sourceId: string
  title: string
  text?: string
}

export const EditKnowledgeBaseReply: FC<EditKnowledgeBaseReplyProps> = ({
  isOpen,
  onClose,
  inputTitle,
  textAreaContent,
  botId,
  replyId,
  setSources,
  sourceId,
  improvementSourceId,
  page,
  fetchKnowledgeReplies,
}) => {
  const [areInitialValues, setAreInitialValues] = useState(true)
  const [loadingTextInsert, setLoadingTextInsert] = useState(Boolean(improvementSourceId))
  const [textInsert, setTextInsert] = useState(null)
  const [level, setLevel] = useState('GENERIC_DOC')


  useEffect(() => {
    if (loadingTextInsert) {
      getTextInsertSource(botId, improvementSourceId)
        .then(res => {
          setTextInsert(res)
        })
        .finally(() => {
          setLoadingTextInsert(false)
        })
    }
  }, [loadingTextInsert])

  const handleSubmitForm = async (text: string, title: string): Promise<void> => {
    const payloadBody: TextInsertPayload = {
      title,
      text,
      type: SourceTypeMap.TEXT,
    }

    if (improvementSourceId) {
      await updateExistingTextSourceForReply({ ...payloadBody, _id: improvementSourceId })
    } else if (replyId) {
      await createTextInsertSourceForReply({ ...payloadBody, replyId })
    } else if (sourceId) {
      await updateExistingTextSource({ ...payloadBody, _id: sourceId })
    }
    onClose()
    alertSuccess('AI Knowledge improved')
    replyId && fetchKnowledgeReplies(page)
  }

  const updateExistingTextSourceForReply = async (
    payload: TextInsertPayload & {
      _id: string
    },
  ): Promise<void> => {
    await updateTextInsert(botId, { ...payload, _id: improvementSourceId })
  }

  const updateExistingTextSource = async (payload: TextInsertPayload & { _id: string }): Promise<void> => {
    const res = await updateTextInsert(botId, { ...payload, _id: sourceId })
    if (setSources) {
      setSources(prevSources => {
        const outdatedSourceIndex = prevSources.findIndex(source => source._id === sourceId)
        const newSources = [...prevSources]
        newSources.splice(outdatedSourceIndex, 1, res)
        return newSources
      })
    }
  }

  const createTextInsertSourceForReply = async (payload: TextInsertPayload & { replyId: number }): Promise<void> => {
    await updateTextKnowledgeSources(botId, { ...payload, replyId, hierarchyLevel: level }, true)
  }

  const handleDialogClose = (): void => {
    if (!areInitialValues) {
      const confirmClosing = confirm('Are you sure you want to leave this page without saving your current changes?')
      if (confirmClosing) onClose()
    } else {
      onClose()
    }
  }

  const optimizeTextInsertTitleLength = (textInsertTitle: string): string => {
    const trimLastCharsLength = 3
    return textInsertTitle.length > maxTextTitleLength
      ? textInsertTitle.slice(0, maxTextTitleLength - trimLastCharsLength) + '...'
      : textInsertTitle
  }

  const replyTableCaption =
    "Edited text will be added to the bot's knowledge, improving its ability to answer similar questions in the future."
  const textSourceCaption = 'Edit text source'

  const options = [
    { value: 'FAQS_FLOWS', label: 'FAQs/flows' },
    { value: 'COMPANY_DOC', label: 'Careco docs' },
    { value: 'GENERIC_DOC', label: 'Generic' },
  ]

  const defaultOption = { value: 'GENERIC_DOC', label: 'Generic' }

  const handleChange = (selectedOption) => {
    setLevel(selectedOption.value)
  }

  return (
    <ModalTemplate
      open={isOpen}
      title="Improve AI Knowledge"
      titleIcon={
        <S.TitleIconWrap>
          <SparklesIcon />
        </S.TitleIconWrap>
      }
      onClose={handleDialogClose}
      containerClasses={classes.modalContainer}
      withFooter={false}>
      <S.TextFormWrap>
        <S.SelectionDiv>
          <S.SubHeader>Knowledge Type</S.SubHeader>
          <Select
            styles={customStyles}
            options={options}
            onChange={handleChange}
            defaultValue={defaultOption}
          />
        </S.SelectionDiv>
        {loadingTextInsert ? (
          <S.LoaderWrap>
            <LoaderSmall showLoader />
          </S.LoaderWrap>
        ) : (
          <TextForm
            onClick={handleSubmitForm}
            isFromEdit
            inputDescription={replyId ? replyTableCaption : textSourceCaption}
            inputText={
              improvementSourceId
                ? optimizeTextInsertTitleLength(textInsert.title)
                : optimizeTextInsertTitleLength(inputTitle)
            }
            textAreaContent={improvementSourceId ? textInsert.text : textAreaContent}
            submitButtonLabel={!improvementSourceId && !sourceId ? 'Add to knowledge' : 'Save'}
            areInitialValues={areInitialValues}
            setAreInitialValues={setAreInitialValues}
          />
        )}
      </S.TextFormWrap>
    </ModalTemplate>
  )
}
