export const xssOptions = {
  whiteList: {
    a: [
      'href',
      'class',
      'target',
      'tabIndex',
      'class',
      'data-href',
      'contenteditable',
      'id',
      'data-extension',
      'style',
    ],
    b: [],
    span: ['style']
  },
}
