import styled from 'styled-components'

export const DuplicateWarningMessage = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--color-text-error);
  position: absolute;
  top: 62px;
  left: 72px;
  width: 220px;
  word-break: break-word;
`
