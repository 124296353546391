import { AnyAction } from 'redux'
import {
  CLEAR_SELECTED_SERVICE_USERS_ID,
  SAVE_CARE_PROFESSIONALS,
  SAVE_SERVICE_USERS,
  SAVE_TOTAL_ELEMENTS,
  SET_ALL_SERVICE_USERS_ID,
  SET_IS_LOADING_SERVICE_USERS,
  SET_SELECTED_SERVICE_USERS_ID,
  UPDATE_CURRENT_PAGE,
  UPDATE_ROWS_PER_PAGE
} from '../actions/serviceUsersActions'

const initialState = {
  serviceUsers: [],
  careProfessionals: [],
  selectedServiceUsersId: [],
  totalElements: 0,
  currentPage: 0,
  rowsPerPage: 10,
  isLoadingServiceUsers: true,
}

const serviceUsersTab = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SAVE_SERVICE_USERS:
      return {
        ...state,
        serviceUsers: action.serviceUsers
      }
    case SAVE_CARE_PROFESSIONALS:
      return {
        ...state,
        careProfessionals: action.careProfessionals
      }
    case SAVE_TOTAL_ELEMENTS:
      return {
        ...state,
        totalElements: action.totalElements
      }
    case SET_IS_LOADING_SERVICE_USERS:
      return {
        ...state,
        isLoadingServiceUsers: action.isLoadingServiceUsers
      }
    case SET_SELECTED_SERVICE_USERS_ID:
      return {
        ...state,
        selectedServiceUsersId:
          state.selectedServiceUsersId.includes(action.selectedServiceUsersId) ?
            [...state.selectedServiceUsersId].filter(id => id !== action.selectedServiceUsersId) :
            [...state.selectedServiceUsersId, action.selectedServiceUsersId]
      }
    case CLEAR_SELECTED_SERVICE_USERS_ID:
      return {
        ...state,
        selectedServiceUsersId: []
      }
    case SET_ALL_SERVICE_USERS_ID:
      return {
        ...state,
        selectedServiceUsersId: state.selectedServiceUsersId.length === state.serviceUsers.length ? [] : state.serviceUsers.map(user => user.id)
      }
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }
    case UPDATE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      }
    default:
      return state
  }
}

export default serviceUsersTab
