import styled from 'styled-components'

export const MultipleEditBarContainer = styled.div`
  border: none;
  border-bottom: 1px solid var(--color-issabeline-grey);
  padding: 10px 20px;
  display: ${props => (props.showMultipleBar ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 45px;
  background-color: var(--color-selago-grey);
  z-index: 1;
`

export const MultipleEditBarLabel = styled.div`
  color: var(--color-text-placeholder);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 24px;
  text-wrap: nowrap;
`

export const MultipleEditSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;

  &:nth-child(1) {
    max-width: 50px;
  }
  &:nth-child(2) {
    max-width: 160px;
  }
  &:nth-child(3) {
    max-width: 160px;
  }
  &:nth-child(4) {
    max-width: 320px;
  }
  &:nth-child(5) {
    max-width: 240px;
  }
  &:nth-child(6) {
    max-width: 220px;
  }
  &:nth-child(7) {
    max-width: 50px;
  }
`

export const MultipleDeleteButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 10px;
  
  & svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`
