import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import NavLink from 'react-router-dom/NavLink'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { HOME_PATH } from '../../../../configs'
import { SUPPORT_STATUSES } from './config'
import { saveActiveUser } from '../../actions/activeUser'

import Badge from '../../../../uiKit/Badge/Badge'
import { styles } from './styles'
import UserNameBlock from '../UserNameBlock/UserNameBlock'
import UserIcon from '../../../../uiKit/UserIcon'
import { LastMsgText } from '../LastMsgText/LastMsgText'
import getLastMessage from '../../helpers/userDialogPreview'

const UserDialogPreview = (props) => {
  const { classes, match, adminUser, activeUser, user } = props

  const isAssigned = user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isAssignedToCurrentAdmin =
    user?.supportRequest?.adminId === adminUser?.id && user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isCurrentChat =
    activeUser?.userId === user?.userId && activeUser?.chatId === user?.chatId

  const isUnread = user?.unreadMessagesCount && !isCurrentChat ? 'unread' : ''

  const boldLastMessage = isUnread || (isAssigned && !isAssignedToCurrentAdmin)

  const lastMsgText =
    isAssigned && !isAssignedToCurrentAdmin ? `Live chat with ${user?.supportRequest?.adminName}` : getLastMessage(user)

  const handleClickUserPreview = () => {
    saveActiveUser(user)
  }

  return (
    <NavLink
      to={`${HOME_PATH}/bot/${match.params.botId}/support/${user?.userId}/${user?.chatId}`}
      activeClassName={classes.activeLink}
    >
      <div className={`${classes.container} wrapper`} onClick={handleClickUserPreview}>
        <UserIcon platform={user?.platform} image={user?.profilePicture} />

        <div className={classes.userInfo}>
          <UserNameBlock user={user} isAssigned={isAssigned} isAssignedToCurrentAdmin={isAssignedToCurrentAdmin} />

          <div className={classes.lastMsgTextWrap}>
            <LastMsgText text={lastMsgText} isUnread={boldLastMessage} />
            {isUnread && <Badge badgeContent={user?.unreadMessagesCount} />}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

UserDialogPreview.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  adminUser: state.adminUser,
  activeUser: state.activeUser,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(UserDialogPreview)))
