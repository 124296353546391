import { UsersManagementType } from '../tabs/users/model/usersModel'
import { getAllCareProfessionals } from '../tabs/serviceUsers/api/serviceUsersApi'

export type TabType = 'One service user' | 'Multiple service users'

export type FormType = {
  first_name: string
  last_name: string
  care_professionals: number[]
}

export type ErrorsType = {
  first_name: string
  last_name: string
}

export enum FormFields {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  CARE_PROFESSIONALS = 'care_professionals'
}

export type CarePlan = {
  type: string
  status: string
  hierarchyLevel: string
  patientId: string
  path: string
  originalFilename: string
  _id: string
}

export type ServiceUserType = {
  id: number
  firstName: string,
  lastName: string,
  botId: number,
  createdAt: string | null,
  duplicatePatient: boolean,
  assignedProfessionals: number[]
  carePlans: CarePlan[] | null
}

export type ServiceUsersManagementType = {
  totalElements: number
  serviceUsers: ServiceUserType[]
}

export type CareProfessionalType = {
  name: string
  id: string
}
