export const CarersSelectorStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
    marginRight: 24
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    border: '1px solid var(--color-mischka-grey)',
    borderRadius: 8,
    outline: 'none',
    boxShadow: 'none',
    minHeight: 46,
    maxHeight: 200,
    cursor: 'pointer',
    '& > div:first-child': {
      maxHeight: 200,
      overflowY: 'auto',
      gap: '12px 8px',
      padding: 8
    },
    '&:hover': {
      border: '1px solid var(--color-mischka-grey)',
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    paddingTop: 4,
    alignItems: 'flex-start'
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none'
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: 'var(--color-text-placeholder)'
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    boxShadow: '0 4px 8px 0 rgba(53, 64, 82, 0.32)',
    borderRadius: 10
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: 200,
    padding: 0
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    background: state.isSelected ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), var(--color-primary-button-primary, #00A0E3)' : 'transparent',
    display: 'flex',
    '& > div': {
      margin: 0,
      lineHeight: '16px',
      padding: '6px 8px 6px 32px',
      borderRadius: '4px',
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), var(--color-primary-button-primary, #00A0E3)',
      '& span:after': {

      }
    },
    '& span': {
      width: 16,
      height: 16,
      top: '50%',
      left: 8,
      transform: 'translateY(-50%)',
      '&::after': {
        left: '4px !important',
        top: '0 !important',
        width: '6px !important',
        height: '11px !important'
      }
    },
    '&:active': {
      background: 'transparent'
    }
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 4,
    padding: '6px 8px',
    margin: 0,
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), var(--color-primary-button-primary, #00A0E3)'
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    paddingLeft: 0,
    fontSize: 14,
    lineHeight: '16px',
    color: 'var(--color-text-secondary)'
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    padding: '0 0 0 10px',
    '&:hover': {
      backgroundColor: 'unset',
      color: 'unset'
    }
  })
}
