import React, { FC, useState } from 'react'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Reducers from 'reducers'
import { HOME_PATH } from 'configs'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { AlertTriangle } from 'uiKit/icons/AlertTriangle'
import { KnowledgeBaseSubTabMap, SourceTypes } from 'tabs/nlp/constants'
import { KnowledgeBaseReply, Source, SourceTypeMap} from 'tabs/nlp/api/websiteKnowledgeBase'

import * as S from './KnowledgeBaseRepliesTable.style'
import classes from './styles.module.scss'
import { EditKnowledgeBaseReply, TextInsertSource } from './EditKnowledgeBaseReply'
import { EditTextInsert } from '../EditTextInsert/EditTextInsert'
import { StopGeneration } from 'uiKit/icons/StopGeneration'
import KnowledgeBaseSources from './KnowledgeBaseSources'
import Select from 'react-select'
import { ButtonContainer } from './KnowledgeBaseRepliesTable.style'

interface KnowledgeBaseRepliesTableProps {
  knowledgeBaseReplies: KnowledgeBaseReply[]
  botId: number
  page: number
  fetchKnowledgeReplies: (page: number) => void
}

const columnNames = [
  { title: 'User phrase', maxWidth: 300 },
  { title: 'AI Knowledge reply', maxWidth: 300 },
  { title: 'Source', maxWidth: 214 },
  { title: 'Sent at', maxWidth: 65 },
  { title: '', maxWidth: 75 },
]

type ReplyWithTextSource = TextInsertSource & { replyId: number }

const getRowBackground = (isFailed: boolean, isStoppedByUser?: boolean) => {
  const defaultStyle = {}
  const failedRowStyles = { background: 'var(--color-peach-schnapps)' }
  const stoppedByUserRowStyles = { background: 'var(--color-egg-white)' }

  return isStoppedByUser ? stoppedByUserRowStyles : isFailed ? failedRowStyles : defaultStyle
}

const KnowledgeBaseRepliesTableContainer: FC<KnowledgeBaseRepliesTableProps & RouteComponentProps> = ({
  knowledgeBaseReplies,
  botId,
  page,
  fetchKnowledgeReplies,
}) => {
  const botTimezone = useSelector((state: ReturnType<typeof Reducers>) => state.activeBot.timezone)
  const [replyWithSource, setReplyWithSource] = useState<ReplyWithTextSource>(null)

  const handleOpenSupportPage = (e: MouseEvent, supportPageURL: string) => {
    e.preventDefault()
    window.open(supportPageURL, '_blank')
  }


  const handleEditReply = ({ sourceId, title, text, replyId }: ReplyWithTextSource) => {
    setReplyWithSource({ sourceId, title, text, replyId })
  }

  // Function to handle opening the source in a new window
  const handleOpenInNewWindowAction = async (link: string) => {
    try {
      const response = await fetch(link)

      if (!response.ok) {
        throw new Error('Failed to fetch the file')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url

      const filename = link.split('/').pop() || 'downloaded_file.docx'
      anchor.download = filename

      anchor.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error while downloading the file:', error)
    }
  }

  return (
    <>
      <Table titles={columnNames}>
        {knowledgeBaseReplies?.map((knowledgeBaseReply, index) => {
          const supportPageURL = knowledgeBaseReply.chatId ? `${HOME_PATH}/bot/${botId}/support/${knowledgeBaseReply.userId}/${knowledgeBaseReply.chatId}` : null
          const isReplySucceeded = !knowledgeBaseReply.isFailed && !knowledgeBaseReply.isStoppedByUser
          return (
            <TableRow
              style={{
                alignItems: 'center',
                ...getRowBackground(knowledgeBaseReply.isFailed, knowledgeBaseReply.isStoppedByUser),
              }}
              key={knowledgeBaseReply.id}
              index={index}>
              <TableCell style={{ maxWidth: 200 }}>
                <S.MessageWrap>{knowledgeBaseReply.userPhrase}</S.MessageWrap>
              </TableCell>
              <TableCell style={{ maxWidth: 200 }}>
                {knowledgeBaseReply.isStoppedByUser ? (
                  <div>
                    <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                    {knowledgeBaseReply.odinReply ? <br /> : null}
                    <span style={{ wordBreak: 'break-word' }}>
                      <StopGeneration /> &nbspReply generation was stopped or interrupted.
                    </span>
                  </div>
                ) : knowledgeBaseReply.isFailed ? (
                  <div>
                    <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                    {knowledgeBaseReply.odinReply ? <br /> : null}
                    <span>
                      <AlertTriangle /> &nbspAn error occurred while generating reply.
                    </span>
                  </div>
                ) : (
                  <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                )}
              </TableCell>

              {/* Replace the source column with the new scrollable list */}
              <TableCell style={{ maxWidth: 214 }}>
                {isReplySucceeded && (
                  <Tooltip classes={{ tooltip: classes.tooltip }} title="Source" placement="top">
                    <KnowledgeBaseSources
                      knowledgeBaseReply={knowledgeBaseReply}
                      onOpenInNewWindowAction={handleOpenInNewWindowAction}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell style={{ maxWidth: 65 }}>
                {moment(knowledgeBaseReply.sentAt).add(botTimezone, 'hours').format('DD.MM.YY HH:mm A')}
              </TableCell>
              <TableCell style={{ maxWidth: 75, maxHeight: 30, justifyContent: 'space-evenly' }}>
                <ButtonContainer>
                  {!knowledgeBaseReply.isFailed && !knowledgeBaseReply.isStoppedByUser && (
                    <EditTextInsert
                      onClick={() =>
                        handleEditReply({
                          sourceId: knowledgeBaseReply.improvementSourceId,
                          title: knowledgeBaseReply.userPhrase,
                          text: knowledgeBaseReply.odinReply,
                          replyId: knowledgeBaseReply.id,
                        })
                      }
                      tooltipTitle="Improve AI knowledge"
                      tooltipPlacement="top"
                      tooltipClasses={{ tooltip: classes.tooltip }}
                      wasEdited={Boolean(knowledgeBaseReply.improvementSourceId)}
                    />
                  )}
                </ButtonContainer>
                <ButtonContainer>
                  <Tooltip placement="top" title="Open conversation" classes={{ tooltip: classes.tooltip }}>
                    <S.ExternalLinkWrap
                      href={supportPageURL}
                      onClick={(e: MouseEvent) => handleOpenSupportPage(e, supportPageURL)}>
                      <ExternalLinkIcon />
                    </S.ExternalLinkWrap>
                  </Tooltip>
                </ButtonContainer>
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
      {Number.isInteger(replyWithSource?.replyId) && (
        <EditKnowledgeBaseReply
          botId={botId}
          isOpen
          onClose={() => setReplyWithSource(null)}
          page={page}
          fetchKnowledgeReplies={fetchKnowledgeReplies}
          replyId={replyWithSource.replyId}
          improvementSourceId={replyWithSource.sourceId}
          inputTitle={replyWithSource.title}
          textAreaContent={replyWithSource.text}
        />
      )}
    </>
  )
}

export const KnowledgeBaseRepliesTable = withRouter(KnowledgeBaseRepliesTableContainer)
