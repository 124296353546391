/* eslint-disable */
import React from 'react'

export const VerticalThreeDots = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.114 12C10.114 10.8954 11.0094 10 12.114 10C13.2186 10 14.114 10.8954 14.114 12C14.114 13.1046 13.2186 14 12.114 14C11.0094 14 10.114 13.1046 10.114 12Z"
      fill="#6D7686"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.114 5C10.114 3.89543 11.0094 3 12.114 3C13.2186 3 14.114 3.89543 14.114 5C14.114 6.10457 13.2186 7 12.114 7C11.0094 7 10.114 6.10457 10.114 5Z"
      fill="#6D7686"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.114 19C10.114 17.8954 11.0094 17 12.114 17C13.2186 17 14.114 17.8954 14.114 19C14.114 20.1046 13.2186 21 12.114 21C11.0094 21 10.114 20.1046 10.114 19Z"
      fill="#6D7686"
    />
  </svg>
)
