import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSearchQuery } from '../../actions/chat'

import Search from '../Search/Search'
import DialogsSection from '../DialogsSection'
import {
  getAllSupportUsers
} from '../../api/users'
import {
  saveAllSupportUsers,
  saveMoreAllSupportUsers,
} from '../../actions/users'

import { useDebounce } from 'hooks/useDebounce'
import { isElementBottom } from 'helpers/isElementBottom'
import { isUrlEndsWithSupport } from 'helpers/isUrlEndsWithSupport'
import { getUrlEnd } from 'helpers/getUrlEnd'
import { SUPPORT_USERS_SIZE } from 'constants/userSize'
import { SupportUserType } from 'models/SupportUserType'

import * as S from './OpenRequests.styles'

interface Props {
  match: any
  assignedToMeUsers: SupportUserType[]
  newRequestsUsers: SupportUserType[]
  expiredRequestsUsers: SupportUserType[]
  assignedRequestsUsers: SupportUserType[]
  allUsers: SupportUserType[]
  adminUserId: number
}

export const OpenRequests: React.FC<Props> = ({
  match,
  assignedToMeUsers,
  newRequestsUsers,
  expiredRequestsUsers,
  assignedRequestsUsers,
  allUsers,
  adminUserId,
}) => {
  const [search, setSearch] = useState('')
  const [hasNext, setHasNext] = useState(true)
  const [loadingChatbot, setLoadingChatbot] = useState(false)

  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    dispatch(updateSearchQuery(debouncedSearch))
  }, [debouncedSearch])

  useEffect(() => {
    setHasNext(true)
    loadAllSupportUsers(saveAllSupportUsers, 0)
  }, [debouncedSearch])

  const handleScroll = event => {
    if (isElementBottom(event.target) && !loadingChatbot && hasNext) {
      loadAllSupportUsers(saveMoreAllSupportUsers, allUsers.length)
    }
  }

  const loadAllSupportUsers = (callback, offset) => {
    setLoadingChatbot(true)
    getAllSupportUsers(match.params.botId, debouncedSearch, offset)
      .then(res => {
        callback(res)
        if (res.length < SUPPORT_USERS_SIZE) setHasNext(false)
      })
      .finally(() => setLoadingChatbot(false))
  }

  return (
    <>
      <S.SearchContainer>
        <Search search={search} handleSearch={setSearch} />
      </S.SearchContainer>
      <S.SectionWrap onScroll={handleScroll}>

        <DialogsSection title="" search={debouncedSearch} users={allUsers} loading={loadingChatbot} />
      </S.SectionWrap>
    </>
  )
}
