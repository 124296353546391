import { store } from '../../../index'
import { CareProfessionalType } from 'models/ServiceUsersTypes'

export const SET_IS_LOADING_SERVICE_USERS = 'SET_IS_LOADING_SERVICE_USERS'
export const SAVE_SERVICE_USERS = 'SAVE_SERVICE_USERS'
export const SAVE_CARE_PROFESSIONALS = 'SAVE_CARE_PROFESSIONALS'
export const SAVE_TOTAL_ELEMENTS = 'SAVE_TOTAL_ELEMENTS'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
export const SET_SELECTED_SERVICE_USERS_ID = 'SET_SELECTED_SERVICE_USERS_ID'
export const SET_ALL_SERVICE_USERS_ID = 'SET_ALL_SERVICE_USERS_ID'
export const CLEAR_SELECTED_SERVICE_USERS_ID = 'CLEAR_SELECTED_SERVICE_USERS_ID'
export const UPDATE_ROWS_PER_PAGE = 'UPDATE_ROWS_PER_PAGE'

export const setIsLoadingServiceUsers = (isLoadingServiceUsers: boolean) => {
  store.dispatch({
    type: SET_IS_LOADING_SERVICE_USERS,
    isLoadingServiceUsers
  })
}

export const saveServiceUsers = (serviceUsers) => {
  store.dispatch({
    type: SAVE_SERVICE_USERS,
    serviceUsers
  })
}

export const saveCareProfessionals = (careProfessionals: CareProfessionalType[]) => {
  store.dispatch({
    type: SAVE_CARE_PROFESSIONALS,
    careProfessionals
  })
}

export const saveTotalElements = (totalElements: number) => {
  store.dispatch({
    type: SAVE_TOTAL_ELEMENTS,
    totalElements
  })
}

export const updateCurrentPage = (currentPage: number) => {
  store.dispatch({
    type: UPDATE_CURRENT_PAGE,
    currentPage
  })
}

export const setSelectedServiceUsersId = (selectedServiceUsersId: number) => {
  store.dispatch({
    type: SET_SELECTED_SERVICE_USERS_ID,
    selectedServiceUsersId
  })
}

export const setAllServiceUsersId = () => {
  store.dispatch({
    type: SET_ALL_SERVICE_USERS_ID
  })
}

export const clearSelectedServiceUsersId = () => {
  store.dispatch({
    type: CLEAR_SELECTED_SERVICE_USERS_ID
  })
}

export const updateRowsPerPage = (rows: number) => {
  store.dispatch({
    type: UPDATE_ROWS_PER_PAGE,
    rowsPerPage: rows
  })
}
