import styled, { keyframes, css } from 'styled-components'

const SourcesTable = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const HeaderWrap = styled.div`
  display: flex;
  border-bottom: 1px solid var(--color-issabeline-grey);
  height: 64px;
  align-items: center;
  gap: 40px;
  padding: 0 24px;
`

const Header = styled.div`
  font-size: 14px;
  color: var(--color-pale-sky);
`

const Button = styled.button`
  background: var(--color-button-secondary);
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: var(--color-text-on-secondary);
  font-size: 16px;
`

const highlightSource = keyframes`
  0% {
    border: 1px solid var(--color-button-secondary);
  }
  100% {
    border: inherit;
  }
`

const Row = styled.div<{ index: number; needHighlight: boolean }>`
  display: flex;
  font-size: 14px;
  color: var(--color-text-primary);
  min-height: 64px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 24px 40px 24px 24px;
  gap: 40px;
  width: 100%;
  background-color: ${({ index }) => (index % 2 != 0 ? 'var(--color-table-row-primary)' : 'none')};
  ${({ needHighlight }) =>
    needHighlight &&
    css`
      animation: ${highlightSource} 4s linear;
    `}} 
  
`

const Source = styled.div`
  ${({ sourceType }) =>
    sourceType !== 'TEXT' &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ sourceType }) =>
    sourceType === 'TEXT' &&
    `
      overflow-x: hidden;
      word-break: break-word;
      white-space: break-spaces;
      & h6 {
        font-weight: 600;
        padding-top: 8px;
        word-break: break-all;
      }
    `}
`

const Delete = styled.div`
  cursor: pointer;
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: calc(100% - 60px);
  bottom: 0;
  opacity: 0.3;
  background-color: var(--color-selago-grey);
`

const Count = styled.div`
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--color-badge-primary);
  cursor: pointer;
  color: var(--color-button-primary);
  display: flex;
`

const SourceDetails = styled.div<{ index: number }>`
  padding: 0 8px 8px 24px;
  background-color: ${({ index }) => (index % 2 != 0 ? 'var(--color-table-row-primary)' : 'none')};
`
const HierarchyLevel = styled.div`
    flex: 0 0 300px;
    width: 150px;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
`

const DeleteWrap = styled.div`
  flex: 0 0 150px;
  width: 200px;
  display: flex;
  gap: 32px;
  align-items: baseline;
  user-select: none;
  justify-content: flex-end;
`


export {
  SourcesTable,
  HeaderWrap,
  Header,
  Button,
  Row,
  Source,
  Delete,
  DisableScreen,
  DeleteWrap,
  Count,
  SourceDetails,
  HierarchyLevel
}
