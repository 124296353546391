import React, { FC } from 'react'
import Modal from '@material-ui/core/Modal'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { BaseButton } from 'uiKit/buttons/BaseButton/BaseButton'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import { ButtonsWrapper, Title } from './CarersSelectAllModal.styles'
import classes from '../../../nlp/components/knowledgeBase/SourcesTable/styles.module.scss'

interface Props {
  open: boolean
  onClose: () => void
  onProceed: () => void
  deselecting: boolean
}

const CarersSelectAllModal: FC<Props> = ({
  open,
  onClose,
  onProceed,
  deselecting
}) => {

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <Title>
          {deselecting
            ? 'By deselecting all care professionals in this list, you are automatically removing them from selected service user(s).'
            : 'By selecting all care professionals in this list, you are automatically assigning them to selected service user(s).'}
        </Title>
        <ButtonsWrapper>
          <BaseButton title='Cancel' onClick={onClose} styles={{ borderRadius: '4px', fontSize: '16px'}}/>
          <SubmitButton onClick={onProceed} title='Proceed' styles={{ borderRadius: '4px', fontSize: '16px'}}/>
        </ButtonsWrapper>
      </div>
    </Modal>
  )
}

export default CarersSelectAllModal
