/**
 * Created by Admin on 02.07.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'
import withStyles from '@material-ui/core/styles/withStyles'
import TablePaginationActionsWrapped from './TablePaginationActionsWrapped'

const styles = theme => ({
  caption: {
    '&:first-of-type': {
      display: 'block!important',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
    },
    display: 'none!important',
  },
  select: {
    border: '1px solid var(--color-mischka-grey)',
    background: 'rgb(var(--color-light))',
    borderRadius: 10,
    paddingLeft: 24,
    color: 'var(--color-button-primary)',
    '&:focus': {
      borderRadius: 10,
      background: 'rgb(var(--color-light))'
    },
    '& li': {
      background: 'black'
    }
  },
  selectIcon: {
    top: 'calc(50% - 12px)',
    right: 18
  },
  menuItem: {
    color: 'var(--color-button-primary)'
  }
})

class Pagination extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <table>
        <tbody>
          <tr>
            <TablePagination
              class="paginationWrapper"
              count={this.props.count}
              rowsPerPage={this.props.rowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 'All']}
              page={this.props.page}
              classes={{
                caption: classes.caption,
                select: classes.select,
                selectIcon: classes.selectIcon,
                menuItem: classes.menuItem
              }}
              onChangePage={this.props.onChangePage}
              onChangeRowsPerPage={this.props.onChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
              SelectProps={{
                style: {
                  display: this.props.hasSelect ? 'block' : 'none',
                }
              }}
              labelDisplayedRows={this.props.labelDisplayedRows}
              labelRowsPerPage={this.props.labelRowsPerPage}
            />
          </tr>
        </tbody>
      </table>
    )
  }
}

Pagination.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  hasSelect: PropTypes.bool,
  labelRowsPerPage: PropTypes.string
}

export default withStyles(styles)(Pagination)
