import React, { FC } from 'react'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { KnowledgeBaseReply } from 'tabs/nlp/api/websiteKnowledgeBase' // Assuming this is where your type is defined
import {
  SourceItem,
  SourceList,
  SourceContainer,
  OpenInNewWindowAction,
  NoItems,
  ItemName, Divider, ExternalLinkWrap,
} from './KnowledgeBaseSources.style'
import Tooltip from '@material-ui/core/Tooltip'
import classes from './styles.module.scss'

interface Props {
  knowledgeBaseReply: KnowledgeBaseReply
  onOpenInNewWindowAction: (link: string) => void
}

const KnowledgeBaseSources: FC<Props> = ({ knowledgeBaseReply, onOpenInNewWindowAction }) => {
  const sources = knowledgeBaseReply.sources ? [...knowledgeBaseReply.sources] : []

  if (!sources.length && !knowledgeBaseReply.isFailed && !knowledgeBaseReply.isStoppedByUser) {
    sources.push({ sourceId: '', source: '', sourceName: '', sourceType: undefined })
  }

  return (
    <SourceContainer>
      <SourceList>
        {sources?.length ? (
          sources.map((source, index) => {
            const isGeneralKnowledge =
              (!source.source && !source.sourceName) &&
              !knowledgeBaseReply.isFailed &&
              !knowledgeBaseReply.isStoppedByUser
            const isLastItem = index === sources.length - 1

            return (
              <><Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={isGeneralKnowledge
                  ? 'The response is based not on trained sources but on the custom prompt info and/or general ODIN knowledge'
                  : source.sourceName || source.source}
                placement="top"
                key={index}
              >
                <SourceItem key={index}
                  onClick={() => {
                    if (!isGeneralKnowledge)
                      onOpenInNewWindowAction(source.source)
                  }}
                >
                  <ItemName>
                    {isGeneralKnowledge ? 'General knowledge' : source.sourceName || new URL(source.source).pathname}
                  </ItemName>

                  {/* Conditionally hide the "Open in New Window" button for GENERAL_KNOWLEDGE */}
                  {!isGeneralKnowledge && (
                    <ExternalLinkWrap>
                      <ExternalLinkIcon />
                    </ExternalLinkWrap>
                  )}
                </SourceItem>
              </Tooltip>
              {!isLastItem && (
                <Divider />
              )}
              </>
            )
          })
        ) : (
          <NoItems>No sources available</NoItems>
        )}
      </SourceList>
    </SourceContainer>
  )
}

export default KnowledgeBaseSources
