import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Buttons from '../Buttons/Buttons'
import { styles } from './styles'
import xss from 'xss'
import { xssOptions } from 'constants/xssOptions'

const GenericMessage = props => {
  const { classes, element, isRtl, postbackIds, searchQuery } = props

  const highlightText = (text, query) => {
    if (!query || !text) return text

    const parts = text.split(new RegExp(`(${query})`, 'gi'))
    return parts
      .map(part => {
        return part.toLowerCase() === query.toLowerCase()
          ? `<span style="background-color: pink">${part}</span>`
          : part
      })
      .join('')
  }

  const highlightedTitle = highlightText(element?.title, searchQuery)
  const highlightedSubtitle = highlightText(element?.subtitle, searchQuery)

  const sanitizedTitle = xss(highlightedTitle, xssOptions)
  const sanitizedSubtitle = xss(highlightedSubtitle, xssOptions)

  return (
    <div className={classes.container}>
      <div className={classes.img} style={{ backgroundImage: 'url(' + element?.imageUrl + ')' }} />

      <div
        className={element?.buttons?.length || element?.subtitle ? classes?.textButtons : classes?.text}
        style={{ textAlign: isRtl && 'end' }}
        dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
      />

      {element?.subtitle && (
        <div
          className={element?.buttons?.length ? classes?.subtitleButtons : classes?.subtitle}
          style={{ textAlign: isRtl && 'end' }}
          dangerouslySetInnerHTML={{ __html: sanitizedSubtitle }}
        />
      )}

      {element?.buttons && !!element?.buttons?.length &&
        <Buttons buttons={element?.buttons} postbackIds={postbackIds}/>}
    </div>
  )
}

GenericMessage.propTypes = {
  classes: PropTypes.object,
  element: PropTypes.object,
  isRtl: PropTypes.bool,
  searchQuery: PropTypes.string,
}

export default withStyles(styles)(GenericMessage)
